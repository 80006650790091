import axios from '../../config/axios'
import { CancelMetingListInterface, ChaneHourStatusInterface, ChangeDayStatusInterface } from '../../interface/Meeting'

export const GetTiming = async (month: string, year: string) => {
    var query = `?month=${month}&year=${year}`
    const { data } = await axios.get(`/v1/user/times/${query}`)
    return data
}

export const CancelMeetingList = async (params: CancelMetingListInterface) => {
    const { data } = await axios.post(`/v1/user/meetings/cancel_meeting_list/`, params)
    return data
}

export const ChangeDayStatus = async (params: ChangeDayStatusInterface) => {
    const { data } = await axios.post(`/v1/user/times/change_day_status/`, params)
    return data
}

export const ChangeHourStatus = async (time_id: string, params: ChaneHourStatusInterface) => {
    const { data } = await axios.put(`/v1/user/times/${time_id}/`, params)
    return data
}