import React from 'react'
import Input from '../../components/Input'
import SearchIcon from '../../assets/images/search.svg'
import ArrowDownIcon from '../../assets/images/arrow_down.svg'
import Button from '../../components/Button'
import ChildrenImage from '../../assets/images/children.png'
import { useNavigate } from 'react-router-dom'

export default function SessionArchive() {

    const navigate = useNavigate()

    return (
        <div className='py-4'>
            <div className='px-6'>
                <div className='text-center md:text-left font-medium text-sm'>
                    Archive
                </div>

                <div className='mt-3 md:flex-row flex-col items-center flex md:justify-between w-full'>
                    <div className='relative mt-2 md:mt-0 w-52'>
                        <Input classNameInput='text-xs px-3' placeholder='Search Names...' />
                        <img src={SearchIcon} className='absolute right-3 top-0 bottom-0 my-auto' />
                    </div>
                    <div className='flex mt-4 md:mt-0 text-xs items-center'>
                        <div>
                            Date
                        </div>
                        <img className='ml-2 w-[9px]' src={ArrowDownIcon} />
                    </div>
                </div>

                <div className='flex flex-wrap gap-4'>
                    <div className='mt-3 flex-1 xl:min-w-[21%] lg:min-w-[30%] md:min-w-[45%] min-w-full p-4 rounded-lg border border-border'>
                        <img src={ChildrenImage} className='w-16 h-16 object-cover rounded-xl' />
                        <div className='mt-3 font-medium'>
                            Adrian Tiana
                        </div>
                        <div className='mt-2 text-xs'>
                            12 Years Old
                        </div>
                        <div className='mt-4 text-xs'>
                            <div className='text-black_opacity_40'>
                                Session Type
                            </div>
                            <div className='flex mt-2 font-medium'>
                                Video Call
                            </div>
                        </div>
                        <div className='mt-4'>
                            <Button
                                title='View Summary'
                                height={48}
                                className='font-medium'
                                onClick={() => navigate('/session-archive/session?hideborder=1')}
                            />
                        </div>
                    </div>
                    <div className='mt-3 flex-1 xl:min-w-[21%] lg:min-w-[30%] md:min-w-[45%] min-w-full p-4 rounded-lg border border-border'>
                        <img src={ChildrenImage} className='w-16 h-16 object-cover rounded-xl' />
                        <div className='mt-3 font-medium'>
                            Adrian Tiana
                        </div>
                        <div className='mt-2 text-xs'>
                            12 Years Old
                        </div>
                        <div className='mt-4 text-xs'>
                            <div className='text-black_opacity_40'>
                                Session Type
                            </div>
                            <div className='flex mt-2 font-medium'>
                                Video Call
                            </div>
                        </div>
                        <div className='mt-4'>
                            <Button
                                title='View Summary'
                                height={48}
                                className='font-medium'
                                onClick={() => navigate('/session-archive/session?hideborder=1')}
                            />
                        </div>
                    </div>
                    <div className='mt-3 flex-1 xl:min-w-[21%] lg:min-w-[30%] md:min-w-[45%] min-w-full p-4 rounded-lg border border-border'>
                        <img src={ChildrenImage} className='w-16 h-16 object-cover rounded-xl' />
                        <div className='mt-3 font-medium'>
                            Adrian Tiana
                        </div>
                        <div className='mt-2 text-xs'>
                            12 Years Old
                        </div>
                        <div className='mt-4 text-xs'>
                            <div className='text-black_opacity_40'>
                                Session Type
                            </div>
                            <div className='flex mt-2 font-medium'>
                                Video Call
                            </div>
                        </div>
                        <div className='mt-4'>
                            <Button
                                title='View Summary'
                                height={48}
                                className='font-medium'
                                onClick={() => navigate('/session-archive/session?hideborder=1')}
                            />
                        </div>
                    </div>
                    <div className='mt-3 flex-1 xl:min-w-[21%] lg:min-w-[30%] md:min-w-[45%] min-w-full p-4 rounded-lg border border-border'>
                        <img src={ChildrenImage} className='w-16 h-16 object-cover rounded-xl' />
                        <div className='mt-3 font-medium'>
                            Adrian Tiana
                        </div>
                        <div className='mt-2 text-xs'>
                            12 Years Old
                        </div>
                        <div className='mt-4 text-xs'>
                            <div className='text-black_opacity_40'>
                                Session Type
                            </div>
                            <div className='flex mt-2 font-medium'>
                                Video Call
                            </div>
                        </div>
                        <div className='mt-4'>
                            <Button
                                title='View Summary'
                                height={48}
                                className='font-medium'
                                onClick={() => navigate('/session-archive/session?hideborder=1')}
                            />
                        </div>
                    </div>
                    <div className='mt-3 flex-1 xl:min-w-[21%] lg:min-w-[30%] md:min-w-[45%] min-w-full p-4 rounded-lg border border-border'>
                        <img src={ChildrenImage} className='w-16 h-16 object-cover rounded-xl' />
                        <div className='mt-3 font-medium'>
                            Adrian Tiana
                        </div>
                        <div className='mt-2 text-xs'>
                            12 Years Old
                        </div>
                        <div className='mt-4 text-xs'>
                            <div className='text-black_opacity_40'>
                                Session Type
                            </div>
                            <div className='flex mt-2 font-medium'>
                                Video Call
                            </div>
                        </div>
                        <div className='mt-4'>
                            <Button
                                title='View Summary'
                                height={48}
                                className='font-medium'
                                onClick={() => navigate('/session-archive/session?hideborder=1')}
                            />
                        </div>
                    </div>
                    <div className='mt-3 flex-1 xl:min-w-[21%] lg:min-w-[30%] md:min-w-[45%] min-w-full p-4 rounded-lg border border-border'>
                        <img src={ChildrenImage} className='w-16 h-16 object-cover rounded-xl' />
                        <div className='mt-3 font-medium'>
                            Adrian Tiana
                        </div>
                        <div className='mt-2 text-xs'>
                            12 Years Old
                        </div>
                        <div className='mt-4 text-xs'>
                            <div className='text-black_opacity_40'>
                                Session Type
                            </div>
                            <div className='flex mt-2 font-medium'>
                                Video Call
                            </div>
                        </div>
                        <div className='mt-4'>
                            <Button
                                title='View Summary'
                                height={48}
                                className='font-medium'
                                onClick={() => navigate('/session-archive/session?hideborder=1')}
                            />
                        </div>
                    </div>
                    <div className='mt-3 flex-1 xl:min-w-[21%] lg:min-w-[30%] md:min-w-[45%] min-w-full p-4 rounded-lg border border-border'>
                        <img src={ChildrenImage} className='w-16 h-16 object-cover rounded-xl' />
                        <div className='mt-3 font-medium'>
                            Adrian Tiana
                        </div>
                        <div className='mt-2 text-xs'>
                            12 Years Old
                        </div>
                        <div className='mt-4 text-xs'>
                            <div className='text-black_opacity_40'>
                                Session Type
                            </div>
                            <div className='flex mt-2 font-medium'>
                                Video Call
                            </div>
                        </div>
                        <div className='mt-4'>
                            <Button
                                title='View Summary'
                                height={48}
                                className='font-medium'
                                onClick={() => navigate('/session-archive/session?hideborder=1')}
                            />
                        </div>
                    </div>
                    <div className='mt-3 flex-1 xl:min-w-[21%] lg:min-w-[30%] md:min-w-[45%] min-w-full p-4 rounded-lg border border-border'>
                        <img src={ChildrenImage} className='w-16 h-16 object-cover rounded-xl' />
                        <div className='mt-3 font-medium'>
                            Adrian Tiana
                        </div>
                        <div className='mt-2 text-xs'>
                            12 Years Old
                        </div>
                        <div className='mt-4 text-xs'>
                            <div className='text-black_opacity_40'>
                                Session Type
                            </div>
                            <div className='flex mt-2 font-medium'>
                                Video Call
                            </div>
                        </div>
                        <div className='mt-4'>
                            <Button
                                title='View Summary'
                                height={48}
                                className='font-medium'
                                onClick={() => navigate('/session-archive/session?hideborder=1')}
                            />
                        </div>
                    </div>
                    <div className='mt-3 invisible flex-1 xl:min-w-[21%] lg:min-w-[30%] md:min-w-[45%] min-w-full p-4 rounded-lg border border-border'>
                                </div>
                </div>

            </div>
        </div>
    )
}
