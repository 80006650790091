import axios from '../../config/axios'
import { CreateConversationInterface } from '../../interface/Messages'

export const GetConversations = async (offset?:number, search?:string, queryText?:string) => {
    var query = `?offset=${offset ?  offset : 0}`
    if(search) {
        query += `&search=${search}`
    }
    const { data } = await axios.get(`/v1/user/message_conversations/${query}${queryText ? queryText : ``}`)
    return data
}

export const GetFollowers = async (search:string) => {
    const {data} = await axios.get(`/v1/user/followers/?q=${search}`)
    return data
}

export const CreateConversation = async (params:CreateConversationInterface) => {
    const { data } = await axios.post(`/v1/user/message_conversations/`, params)
    return data
}

export const GetMessagesConversation = async (conversation_id:any) => {
    const { data } = await axios.get(`/v1/user/message_conversations/${conversation_id}/messages/`)
    return data
}

export const GetInfoConversation = async (conversation_id:any) => {
    const { data } = await axios.get(`/v1/user/message_conversations/${conversation_id}/`)
    return data
}

export const SendMessage = async (conversation_id:any, params:any) => {
    const { data } = await axios.post(`/v1/user/message_conversations/${conversation_id}/messages/`, params)
    return data
}