import DownloadIcon from '../../assets/images/download.svg'
import DeleteIcon from '../../assets/images/delete.svg'
import UploadBox from '../UploadBox'
import { Fragment, useState } from 'react'
import Button from '../Button'
import { useDeleteDocuments, useGetDocuments, useUploadDocuments } from '../../hook/user'
import PageLoading from '../PageLoading'
import { toast } from 'react-toastify'
import ReactLoading from 'react-loading'

export default function Documents() {

    const getDocuments = useGetDocuments()
    const [resumeFiles, setResumeFiles] = useState<File[]>([])
    const [loading, setLoading] = useState('')
    const [deleteLoading, setDeleteLoading] = useState('')
    const uploadDocument = useUploadDocuments()
    const deleteDocument = useDeleteDocuments()

    const resumeDelete = (index: number) => {
        const resumes = [...resumeFiles]
        resumes.splice(index, 1)
        setResumeFiles(resumes)
    }

    const upload = (file: File, category: string) => {

        setLoading(category)

        const formData = new FormData()
        formData.append('category', category)
        formData.append('document', file)

        uploadDocument.mutateAsync(formData).then((res) => {
            getDocuments.refetch()
            setLoading('')
        }).catch((err) => {
            toast.error(err?.response?.data?.message)
            setLoading('')
        })
    }

    const downloadFile = (src: string) => {
        window.open(
            src,
            '_blank'
        )
    }

    const fileDelete = (id: string) => {
        setDeleteLoading(id)


        deleteDocument.mutateAsync(id).then((res) => {
            getDocuments.refetch()
            setLoading('')
            toast.success('File deleted successfully')
        }).catch((err) => {
            toast.error(err?.response?.data?.message)
            setLoading('')
        })
    }

    return (
        <div className='p-5'>

            {
                getDocuments.isInitialLoading ?
                    <PageLoading />
                    :
                    <Fragment>
                        <div className='text-xs'>
                            Resume
                        </div>
                        <div>

                            <div className='mt-2'>
                                <UploadBox
                                    placeholder='+ Choose File'
                                    readonly
                                    onChange={(file) => upload(file, 'resume')}
                                    isLoading={loading === 'resume' ? true : false}
                                />
                            </div>

                            {
                                getDocuments.data?.map((item: any, index: number) => {
                                    if (item.category === 'resume') {
                                        return (
                                            <div key={index} className='mt-2 h-12 px-4 bg-third rounded-md flex justify-between items-center'>
                                                <div className='text-sm  text-primary'>
                                                    {'File ' + (index + 1)}
                                                </div>
                                                <div className='flex'>
                                                    <img onClick={() => downloadFile(item.document)} src={DownloadIcon} />
                                                    {
                                                        deleteLoading === item.id ?
                                                            <ReactLoading type='spin' color='#4C4DDC' width={18} height={18} className='ml-4' />
                                                            :
                                                            <img onClick={() => fileDelete(item.id)} className='ml-4' src={DeleteIcon} />
                                                    }
                                                </div>
                                            </div>
                                        )
                                    } else return null
                                })
                            }

                        </div>

                        <div className='text-xs mt-4'>
                            degree of education
                        </div>

                        <div className='mt-3'>
                            <div className='mt-2'>
                                <UploadBox
                                    placeholder='+ Choose File'
                                    readonly
                                    onChange={(file) => upload(file, 'degree')}
                                    isLoading={loading === 'degree' ? true : false}
                                />
                            </div>

                            {
                                getDocuments.data?.map((item: any, index: number) => {
                                    if (item.category === 'degree') {
                                        return (
                                            <div key={index} className='mt-2 h-12 px-4 bg-third rounded-md flex justify-between items-center'>
                                                <div className='text-sm  text-primary'>
                                                    {'File ' + (index + 1)}
                                                </div>
                                                <div className='flex'>
                                                    <img onClick={() => downloadFile(item.document)} src={DownloadIcon} />
                                                    {
                                                        deleteLoading === item.id ?
                                                            <ReactLoading type='spin' color='#4C4DDC' width={18} height={18} className='ml-4' />
                                                            :
                                                            <img onClick={() => fileDelete(item.id)} className='ml-4' src={DeleteIcon} />
                                                    }
                                                </div>
                                            </div>
                                        )
                                    } else return null
                                })
                            }
                        </div>

                        <div className='text-xs mt-4'>
                            Other Documents
                        </div>

                        <div className='mt-3'>
                        <div className='mt-2'>
                                <UploadBox
                                    placeholder='+ Choose File'
                                    readonly
                                    onChange={(file) => upload(file, 'other')}
                                    isLoading={loading === 'other' ? true : false}
                                />
                            </div>

                            {
                                getDocuments.data?.map((item: any, index: number) => {
                                    if (item.category === 'other') {
                                        return (
                                            <div key={index} className='mt-2 h-12 px-4 bg-third rounded-md flex justify-between items-center'>
                                                <div className='text-sm  text-primary'>
                                                    {'File ' + (index + 1)}
                                                </div>
                                                <div className='flex'>
                                                    <img onClick={() => downloadFile(item.document)} src={DownloadIcon} />
                                                    {
                                                        deleteLoading === item.id ?
                                                            <ReactLoading type='spin' color='#4C4DDC' width={18} height={18} className='ml-4' />
                                                            :
                                                            <img onClick={() => fileDelete(item.id)} className='ml-4' src={DeleteIcon} />
                                                    }
                                                </div>
                                            </div>
                                        )
                                    } else return null
                                })
                            }
                        </div>

                        
                    </Fragment>
            }

        </div>
    )
}
