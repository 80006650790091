import { createContext, useState, useEffect } from "react"

interface GlobalInfterFace {
    windowSize: number
}

export const Global = createContext<GlobalInfterFace>({
    windowSize: 0,
})

export const GlobalProvider = (props: any) => {
    const [windowSize, setWindowSize] = useState<number>(0)

    useEffect(() => {



        function updateSize() {
            setWindowSize(window.innerWidth)
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);

    }, [])

    return (
        (
            <Global.Provider value={{
                windowSize: windowSize
            }}>
                {props.children}
            </Global.Provider>
        )
    )
}
