import Countdown from 'react-countdown'
import ClockIcon from '../../assets/images/clock.svg'
import CallEndIcon from '../../assets/images/call_end.svg'
import Button from '../../components/Button'
import { Fragment, useState } from 'react'
import Toggle from '../../components/Toggle'
import { useSearchParams } from 'react-router-dom'
import { useGetParentDetail, useStartMeeting, useSubmitMeeting } from '../../hook/patients'
import PageLoading from '../../components/PageLoading'
import { JitsiMeeting } from '@jitsi/react-sdk'
import ParentInformation from "../../components/patients/ParentInformation";
import { toast } from 'react-toastify'
import Input from '../../components/Input'
import ConversationComponent from '../../components/messages/ConversationComponent'
import { useGetProfileInfo } from '../../hook/user'

export default function Session() {

    const [searchParams] = useSearchParams()
    const startMeeting = useStartMeeting()
    const parentId = searchParams.get('parent-id')
    const getProfile = useGetProfileInfo()
    const getParentDetail = useGetParentDetail(parentId)
    const submitMeeting = useSubmitMeeting(getParentDetail.data?.next_meeting?.id)
    const [toggleChatProfile, setToggleChatProfile] = useState<number>(1)
    const [startSession, setStartSession] = useState(false)
    const [finishSession, setFinishSession] = useState(false)
    const [roomName, setRoomName] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [isSubmitMeeting, setIsSubmitMeeting] = useState<boolean>(false)

    const renderSpinner = () => (
        <PageLoading />
    );

    if (parentId === null) {
        return null
    }

    const handleStartMeeting = () => {
        startMeeting.mutateAsync(getParentDetail.data?.next_meeting?.id).then((res) => {
            setRoomName(res.room_name)
            setStartSession(true)
        }).catch((err) => {
            toast.error(err.response?.data?.message)
        })

    }

    const handleSubmitMeeting = () => {
        const params = {
            description: description
        }
        submitMeeting.mutateAsync(params).then((_) => {
            setIsSubmitMeeting(true)
            toast.success('The operation done successfully')
        }).catch((err) => {
            toast.error(err.response?.data?.message)
        })
    }

    return (
        <div className='flex-1 bg-[#F7F8FB]'>
            {
                getParentDetail.isLoading ?
                    <PageLoading />
                    :
                    <Fragment>
                        <div className='flex flex-col md:flex-row'>
                            <div
                                className={`flex-1 ${!startSession && 'py-10'} md:py-0 flex flex-col bg-white h-[552px] rounded-2 border border-border`}>
                                {
                                    !getParentDetail.data?.next_meeting ?
                                        <Fragment>
                                            <div className='flex-1 flex flex-col justify-center items-center'>
                                                <div className='mt-6'>
                                                    Not found any meeting for this patient
                                                </div>
                                            </div>
                                        </Fragment>
                                        :
                                        finishSession ?
                                            <Fragment>
                                                <div className='flex-1 flex flex-col justify-center items-center'>
                                                    <div className='flex-1 flex flex-col justify-center items-center'>
                                                        <img src={CallEndIcon} />

                                                        <div
                                                            className='lg:mt-[88px] text-center mt-10 text-primary font-medium text-3xl'>
                                                            the video call finished.
                                                        </div>
                                                    </div>

                                                    {
                                                        !isSubmitMeeting &&
                                                        <div className='mt-8 w-full px-5 pb-4'>
                                                            <Input
                                                                type='textarea'
                                                                label='Description'
                                                                value={description}
                                                                onChange={(e) => setDescription(e.target.value)}
                                                            />
                                                            <Button
                                                                title='Submit'
                                                                onClick={handleSubmitMeeting}
                                                                isLoading={submitMeeting.isLoading}
                                                            />
                                                        </div>
                                                    }

                                                </div>

                                            </Fragment>
                                            :
                                            startSession ?
                                                <div className='h-[600px]'>
                                                    <JitsiMeeting
                                                        domain='www.irounishopping.ir'
                                                        roomName={roomName}
                                                        configOverwrite={{
                                                            startWithAudioMuted: true,
                                                            disableModeratorIndicator: true,
                                                            startScreenSharing: true,
                                                            enableEmailInStats: false,
                                                            disableDeepLinking: true,
                                                            readOnlyName: true,
                                                        }}
                                                        spinner={renderSpinner}
                                                        interfaceConfigOverwrite={{
                                                            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                                                        }}
                                                        userInfo={{
                                                            displayName: getProfile?.data?.first_name + ' ' + getProfile?.data?.last_name,
                                                            email: ''
                                                        }}
                                                        onApiReady={(externalApi) => {

                                                            // here you can attach custom event listeners to the Jitsi Meet External API
                                                            // you can also store it locally to execute commands
                                                        }}
                                                        onReadyToClose={() => setFinishSession(true)}
                                                        getIFrameRef={(iframeRef) => {
                                                            iframeRef.style.height = '100%';
                                                            // iframeRef.style.minHeight = '500px';
                                                        }}
                                                    />
                                                </div>
                                                :
                                                <Fragment>
                                                    <div className='flex-1 flex flex-col justify-center items-center'>
                                                        <img src={ClockIcon} />
                                                        <div
                                                            className='tracking-widest text-primary text-4xl mt-14 font-medium'>
                                                            <Countdown
                                                                date={getParentDetail.data?.next_meeting?.start_time}
                                                                intervalDelay={0}
                                                                precision={2}
                                                                count={1}
                                                            />
                                                        </div>
                                                        <div className='mt-6'>
                                                            until the start of the session
                                                        </div>

                                                    </div>
                                                    <div
                                                        className='flex mt-5 md:mt-0 flex-col sm:flex-row justify-between items-center md:pb-6 px-6'>
                                                        <div className='flex flex-col sm:flex-row'>
                                                            <Button
                                                                title='Cancel The Session'
                                                                className='bg-secondary whitespace-nowrap px-4 font-medium'
                                                                textColor='rgba(39, 61, 139, 0.4)'
                                                                fontSize={16}
                                                            />
                                                            {/* <Button
                                                                title='Message'
                                                                className='bg-secondary whitespace-nowrap px-4 mt-4 sm:mt-0 sm:ml-5 font-medium'
                                                                textColor='#273D8B'
                                                                fontSize={16}
                                                            /> */}
                                                        </div>
                                                        <div className='mt-4 sm:mt-0'>
                                                            <Button
                                                                title='start    >'
                                                                className='px-4 font-medium'
                                                                fontSize={16}
                                                                onClick={handleStartMeeting}
                                                                isLoading={startMeeting.isLoading}
                                                            />
                                                        </div>
                                                    </div>
                                                </Fragment>
                                }
                            </div>

                            <div
                                className='md:w-[354px] w-full relative mt-4 md:mt-0 md:ml-6 flex flex-col bg-white h-[552px] rounded-2 border border-border'>
                                <div className='h-[62px] border-b border-border flex justify-end px-3 items-center'>
                                    <div className='w-[160px]'>
                                        <Toggle
                                            items={[
                                                {
                                                    id: 1,
                                                    text: 'profile'
                                                },
                                                {
                                                    id: 2,
                                                    text: 'chat'
                                                },
                                            ]}
                                            onChange={(value) => setToggleChatProfile(value)}
                                            toggle={toggleChatProfile}
                                        />
                                    </div>
                                </div>
                                {
                                    toggleChatProfile === 1 ?
                                        <div className='px-6 mt-4 flex-1 flex flex-col'>
                                            <div className='flex'>
                                                <img src={getParentDetail.data?.learner?.avatar}
                                                    className='w-[105px] h-[105px] object-cover rounded-xl' />
                                                <div className='ml-3 mt-2 flex-1'>
                                                    <div className='text-xl text-primary font-medium'>
                                                        {getParentDetail.data?.learner?.__str__}
                                                    </div>
                                                    <div className='text-sm text-[#6A6F8F] mt-2'>
                                                        {getParentDetail.data?.learner?.age} years old
                                                    </div>
                                                    <div className='text-sm text-[#6A6F8F] mt-2 flex justify-between'>
                                                        <div>
                                                            {
                                                                getParentDetail.data?.learner?.gender === 'M' ?
                                                                    'Boy'
                                                                    :
                                                                    'Girl'
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='mt-4'>
                                                <div className='mt-2 flex justify-between text-sm capitalize'>
                                                    <div>
                                                        Mother's name:
                                                    </div>
                                                    <div className='text-[#737896]'>
                                                        {getParentDetail.data?.learner?.mother_name}
                                                    </div>
                                                </div>
                                                <div className='mt-2 flex justify-between text-sm capitalize'>
                                                    <div>
                                                        Father's name:
                                                    </div>
                                                    <div className='text-[#737896]'>
                                                        {getParentDetail.data?.learner?.first_name}
                                                    </div>
                                                </div>
                                                <div className='mt-2 flex justify-between text-sm capitalize'>
                                                    <div>
                                                        Phone number:
                                                    </div>
                                                    <div className='text-[#737896]'>
                                                        {getParentDetail.data?.parent?.phone_number}
                                                    </div>
                                                </div>

                                            </div>
                                            {/* <div className='flex-1 pb-6 flex items-end'>
                                                <div
                                                    className='mt-5 w-full h-10 rounded-[4px] px-3 flex justify-between items-center border border-border'>
                                                    <div className='text-primary text-xs font-medium'>
                                                        Diagnostic evaluation
                                                    </div>
                                                    <img src={EyeBlueIcon} />
                                                </div>
                                            </div> */}
                                        </div>
                                        :
                                        <div className='px-6 relative mt-4 flex-1 flex pb-6 flex-col justify-end'>
                                            <ConversationComponent conversation_id={getParentDetail.data?.user_message_conversation?.id} />
                                        </div>
                                }
                            </div>
                        </div>
                        <div className='mt-6 bg-white rounded-2 px-4 py-6 border border-border'>
                            <ParentInformation parentId={parentId} />
                        </div>
                    </Fragment>
            }


        </div>
    )
}
