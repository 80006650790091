import ReactLoading from 'react-loading'


const PageLoading = () => {
    return (
        <div className='flex justify-center'>
            <ReactLoading
                color='#4C4DDC'
                width={60}
            />
        </div>
    )
}

export default PageLoading
