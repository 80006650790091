import {useGetParentDetail} from "../../hook/patients";
import PageLoading from "../PageLoading"
import {XMarkIcon} from '@heroicons/react/24/outline'
import moment from "moment"
import ParentInformation from "./ParentInformation";

interface Props {
    open: boolean,
    close: () => void,
    parentId: string,
}

export default function ModalParentInformation(props: Props) {

    const getParentDetail = useGetParentDetail(props.parentId)


    return (
        <>
            {
                props.open ? (

                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none max-w-3xl mx-auto">
                            <div className="relative h-full sm:h-auto w-full my-6 p-2">
                                <div
                                    className="border-0 h-full p-5 lg:min-w-full overflow-y-auto rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {
                                        getParentDetail.isLoading ?
                                            <PageLoading/>
                                            :
                                            <>
                                                <div
                                                    className="w-full flex justify-between pb-4 border-b border-border">
                                                    <div className="flex items-center gap-8 gap-y-3 flex-wrap">
                                                        <div className="flex items-center">
                                                            <img
                                                                src={getParentDetail.data?.learner?.avatar_32}
                                                                className="w-8 h-8 object-cover rounded-full"
                                                            />
                                                            <div className="ml-3 text-title font-medium">
                                                                {getParentDetail.data?.learner?.__str__}
                                                            </div>
                                                        </div>

                                                        <div className="flex text-sm gap-1">
                                                            <div className="text-black_opacity_20">
                                                                Age:
                                                            </div>
                                                            <div>
                                                                {getParentDetail.data?.learner?.age_string}
                                                            </div>
                                                        </div>

                                                        <div className="flex text-sm gap-1">
                                                            <div className="text-black_opacity_20">
                                                                Registration:
                                                            </div>
                                                            <div>
                                                                {moment(getParentDetail.data?.parent?.date_joined).format("l")}
                                                            </div>
                                                        </div>

                                                        <div className="flex text-sm gap-1">
                                                            <div className="text-black_opacity_20">
                                                                Gender:
                                                            </div>
                                                            <div>
                                                                {getParentDetail.data?.learner?.gender === 'M' ? 'Boy' : 'Girl'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <XMarkIcon onClick={props.close} className="w-10 sm:hidden"/>
                                                </div>
                                                <ParentInformation parentId={props.parentId}/>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>

                        <div onClick={props.close} className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null

            }

        </>
    )
}
