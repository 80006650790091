import axios from '../../config/axios'

export const GetTherapiistsList = async (offset: number = 0, search?: string) => {
    let query = ``
    if (search && search !== '') {
        query += `&search=${search}`
    }
    const {data} = await axios.get(`/v1/receptionist/therapists/?offset=${offset}${query}`)
    return data
}

export const GetTherapistTime = async (therapist_id:string, month:any, year:any) => {
    var query = `?month=${month}&year=${year}`
    const {data} = await axios.get(`/v1/receptionist/therapists/${therapist_id}/times/${query}`)
    return data
}

export const GetTherapistDetail = async (therapist_id:string) => {
    const {data} = await axios.get(`/v1/receptionist/therapists/${therapist_id}/`)
    return data
}