import Button from "../Button";
import Input from "../Input"
import SearchIcon from '../../assets/images/search.svg'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from "react";
import { useCreateConversation, useGetFollowers } from "../../hook/messages"
import ReactSelect from 'react-select'
import { CreateConversationInterface } from "../../interface/Messages";
import { toast } from "react-toastify";

interface Props {
    open: boolean,
    close: () => void,
}

export default function ModalSendNewMessage(props: Props) {

    const [users, setUsers] = useState<any>([])
    const [search, setSearch] = useState<string>('')
    const [userSelected, setUserSelected] = useState<any>()
    const [message, setMessage] = useState<string>('')
    const getFollowers = useGetFollowers(search)
    const createConversation = useCreateConversation()

    useEffect(() => {
        const array: any = []

        getFollowers.data?.map((item: any) => {
            array.push({
                value: item.id,
                label: item.first_name + ' ' + item.last_name
            })
        })

        setUsers(array)

    }, [getFollowers.data])

    const handleCreateConversation = () => {

        if (userSelected && message.length > 0) {
            const params: CreateConversationInterface = {
                follower: userSelected.value,
                message: message
            }
            createConversation.mutateAsync(params).then((res) => {
                toast.success('The operation done successfully')
                window.location.reload()
            }).catch((err) => {
                toast.error(err.response?.data?.message)
            })
        } else {
            toast.error('Please complete the form')
        }


    }

    return (
        <>
            <div onClick={props.close} className={`${!props.open ? 'hidden invisible' : ''} visible transition-all bg-black_opacity_40 fixed w-full h-full top-0 left-0 z-30`}>
            </div>
            <div className={`fixed top-0 overflow-y-auto right-0 z-40 sm:w-[482px] w-full px-5 py-5 h-full transition-all duration-500 transform bg-white ${props.open ? 'translate-x-0' : 'translate-x-full'}`}>

                <div>
                    <XMarkIcon onClick={props.close} className="w-6 mb-2 sm:hidden" />
                </div>

                <div className="flex justify-between items-center border-b border-border pb-4">
                    <div className="font-medium">
                        Start A New Conversation
                    </div>
                    <div className='w-[72px]'>
                        <Button
                            title='Send'
                            isLoading={createConversation.isLoading}
                            onClick={handleCreateConversation}
                        />
                    </div>
                </div>

                <div className="mt-4 text-xs mb-2">
                    With
                </div>

                <ReactSelect
                    value={userSelected}
                    onChange={(newValue) => setUserSelected(newValue)}
                    onInputChange={(newValue) => setSearch(newValue)}
                    placeholder='Search Names'
                    isSearchable
                    options={users} />

                <Input
                    label="Starting Message"
                    type="textarea"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />

            </div>
        </>
    )
}
