import { useEffect, useState } from 'react'
import ChildrenImage from '../../assets/images/children.png'
import Switch from '../Switch'
import ModalConfrimCancelRequests from './ModalConfrimCancelRequests'
import moment from 'moment'
import { useChangeDayStatus } from '../../hook/timing'
import { ChangeDayStatusInterface } from '../../interface/Meeting'
import { toast } from 'react-toastify'

interface Props {
    date: any,
    data: any,
    refetch: () => void,
}

export default function DetailOfDay(props: Props) {

    const [showModalConfrimCancel, setShowModalConfrimCancel] = useState<boolean>(false)
    const [sessions, setSessions] = useState<any[]>([])
    const [active, setActive] = useState(true)
    const changeDayStatus = useChangeDayStatus()

    useEffect(() => {

        if (props.data && props.date) {
            const array: any[] = []
            var count = 0
            props.data?.map((item: any) => {
                if (moment(item.start_time).format('YYYY-MM-DD') == props.date) {
                    if (item.user_meeting) {
                        array.push(item)
                    }
                    if (!item.is_active) {
                        count++
                        setActive(false)
                    }

                }
            })
            if (count == 0) {
                setActive(true)
            }
            setSessions(array)
        }

    }, [props.data && props.date])


    const handleDayChangeStatus = (value: boolean) => {
        const params: ChangeDayStatusInterface = {
            date: props.date,
            is_active: value,
        }

        changeDayStatus.mutateAsync(params).then((_) => {
            setActive(value)
            props.refetch()
        }).catch((err) => {
            toast.error(err.response?.data?.message)
        })
    }


    if (props.data && props.date)
        return (
            <div className='lg:w-[504px]'>
                <div className='text-xs'>
                    Status Of The Chosen Day
                </div>
                <div className='mt-3 px-3 border rounded-1 border-border'>
                    <div className='sm:h-14 sm:py-0 py-3 sm:flex-row flex-col border-b border-border flex sm:items-center justify-between'>
                        <div className='font-medium text-primary text-xs'>
                            {moment(props.date).format('ll')}
                        </div>
                        {
                            moment().format('YYYY-MM-DD') <= props.date ?
                                <div className='flex justify-between sm:justify-start items-center sm:mt-0 mt-3'>
                                    <div className='text-xs'>
                                        Is This Day a Working Day?
                                    </div>
                                    <div className='ml-2'>
                                        <Switch
                                            active={active}
                                            onChange={handleDayChangeStatus}
                                            isLoading={changeDayStatus.isLoading}
                                        />
                                    </div>
                                </div>
                                : null
                        }

                    </div>
                    <div className='mt-3'>
                        <div className='text-xs font-medium'>
                            You Have {sessions.length} Sessions In This Day
                        </div>
                        <div className='flex flex-wrap mt-3 gap-2'>
                            {
                                sessions.map((item: any) => {
                                    if (moment(item.start_time).format('YYYY-MM-DD') == props.date && item.user_meeting) {
                                        return (
                                            <div className='h-8 bg-secondary items-center rounded-1 flex px-2'>
                                                <img src={item?.user_meeting?.parent?.avatar} className='w-6 h-6 rounded-full border border-primary' />
                                                <div className='ml-2 text-xs font-medium text-primary'>
                                                    {item?.user_meeting?.parent?.__str__}
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>

                    </div>
                    {/* <div onClick={() => setShowModalConfrimCancel(true)} className='h-[45px] cursor-pointer sm:text-sm text-xs text-center font-medium flex items-center sm:justify-end justify-center px-5 text-[#C22A2A]'>
                        Send a Request to Cancel All Sessions
                    </div> */}
                </div>

                <ModalConfrimCancelRequests
                    close={() => setShowModalConfrimCancel(false)}
                    open={showModalConfrimCancel}
                    data={sessions}
                    date={props.date}
                    refetch={() => props.refetch()}
                />
            </div>
        )
    else return null
}
