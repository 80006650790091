import { useContext, useEffect, useState } from 'react'
import { Calendar } from 'react-multi-date-picker'
import { Global } from '../../contexts/global'
import { useGetTiming } from '../../hook/timing'
import moment from 'moment'
import PickAnHour from '../../components/manage-timing/PickAnHour'
import DetailOfDay from '../../components/manage-timing/DetailOfDay'
import DetailOfHour from '../../components/manage-timing/DetailOfHour'

export default function ManageTiming() {

    const { windowSize } = useContext(Global)
    const [timeSelected, setTimeSelected] = useState<string | undefined>()
    const [time, setTime] = useState<any>()
    const getTiming = useGetTiming(time?.month, time?.year)
    const [datesHaveMeeting, setdatesHaveMeeting] = useState<string[]>([])
    const [dateSelected, setDateSelected] = useState<string>()
    const [allDatesExsist, setAllDatesExsist] = useState<string[]>([])
    const [finishMap, setFinishMap] = useState<boolean>(false)
    const [disableDates, setDisableDates] = useState<string[]>([])

    const handleChangeDate = (dateObject: any) => {
        var date = dateObject.year + '-' + dateObject.month.number + '-' + dateObject.day
        setTimeSelected(undefined)
        setDateSelected(moment(date).format('YYYY-MM-DD'))
    }

    useEffect(() => {

        const date = new Date()
        setTime({
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        })

    }, [])


    useEffect(() => {

        const array: string[] = []
        const allDates: string[] = []
        getTiming.data?.map((item: any, i: number) => {
            const date = moment(item.start_time).format('YYYY-MM-DD')
            const index = array.indexOf(date)
            const index2 = allDates.indexOf(date)
            if (index2 === -1) {
                allDates.push(moment(item.start_time).format('YYYY-MM-DD'))
            }
            if (index === -1) {
                if (item.user_meeting) {
                    array.push(date)
                }
            }

            if (i == getTiming.data.length - 1) {
                setFinishMap(true)
            }

        })
        setdatesHaveMeeting(array)
        setAllDatesExsist(allDates)


    }, [getTiming.data])

    useEffect(() => {

        if (finishMap && getTiming.data) {
            const array: string[] = [...allDatesExsist]

            getTiming.data?.map((item: any) => {
                if (item.is_active) {
                    const date = moment(item.start_time).format('YYYY-MM-DD')
                    const index = array.indexOf(date)
                    if(index > -1) {
                        array.splice(index, 1)
                    }
                }
            })
            setDisableDates(array)
        }

    }, [finishMap, allDatesExsist, getTiming.data])


    const handleChangeMonthYear = (d: any) => {
        setTime({
            month: d.month.number,
            year: d.year,
        })
    }

    return (
        <div className='p-4'>
            <div className='flex sm:flex-row flex-col gap-4 justify-between text-sm'>
                <div className='font-medium'>
                    Manage your Calendar
                </div>
                <div className='font-medium text-primary'>
                    Today is {moment().format('ll')}
                </div>
            </div>

            <div className='mt-5 text-xs'>
                Your Selected Time Is
            </div>

            <div className='p-4 rounded-sm w-fit mt-2 text-primary bg-secondary sm:text-sm text-xs font-medium'>
                {moment(dateSelected).format('ll')}
            </div>

            <div className='flex lg:flex-row flex-col mt-5 gap-3'>
                <div>
                    <div className='text-xs font-medium'>
                        Pick a Day
                    </div>
                    <div className='mt-3'>
                        <Calendar
                            numberOfMonths={windowSize < 640 ? 1 : 2}
                            disableMonthPicker
                            disableYearPicker
                            onChange={handleChangeDate}
                            mapDays={({ date }) => {
                                let color
                                var d = moment(date.year + '-' + date.month + '-' + date.day).format('YYYY-MM-DD')
                                const obj = datesHaveMeeting.find(o => o === d)
                                const index = disableDates.indexOf(d)
                                if (obj) color = "red"
                                if (color) return { className: "highlight highlight-" + color }
                                if (index != -1) return { className: "highlight highlight-" + 'gray' }
                            }}
                            onMonthChange={handleChangeMonthYear}
                            onYearChange={handleChangeMonthYear}
                        />
                    </div>
                </div>

                <PickAnHour
                    data={getTiming.data}
                    date={dateSelected}
                    onTimeSelected={(time_id: string) => setTimeSelected(time_id)}
                />

            </div>

            <div className='mt-5 flex lg:flex-row flex-col gap-3'>


                <DetailOfDay
                    date={dateSelected}
                    data={getTiming.data}
                    refetch={() => getTiming.refetch()}
                />

                <DetailOfHour
                    data={getTiming.data}
                    timeId={timeSelected}
                    refetch={() => getTiming.refetch()}
                    date={dateSelected}
                />
            </div>

        </div>
    )
}
