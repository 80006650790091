import Button from "./Button";

interface Props {
    text?: string,
    close: () => void,
    submit: (type: string) => void,
    type: string,
    isLoading?: boolean,
}

export default function ModalConfirm(props: Props) {
    return (
        <div id="popup-modal" tabIndex={-1} className="fixed top-0 left-0 right-0 z-50 flex justify-center items-center overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow border border-border">
                    <button onClick={props.close} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-hide="popup-modal">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-6 text-center">
                        <svg aria-hidden="true" className="mx-auto mb-4 text-gray-400 w-14 h-14 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <h3 className="mb-7 text-lg da">
                            {
                                props.text ?
                                    props.text
                                    :
                                    'Are you sure to perform this operation?'
                            }
                        </h3>
                        {/* <button onClick={() => props.submit(props.type)} data-modal-hide="popup-modal" type="button" className="text-white bg-primary font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                            Yes, I'm sure
                        </button> */}
                        <div className="flex justify-center gap-2">
                            <div className="w-32">
                                <Button
                                    onClick={() => props.submit(props.type)}
                                    title="Yes, I'm sure"
                                    isLoading={props.isLoading}
                                />
                            </div>
                            <button onClick={props.close} data-modal-hide="popup-modal" type="button" className="text-primary bg-secondary rounded-lg border border-gray-200 text-sm font-medium px-5 h-10">No, cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
