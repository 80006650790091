import { useEffect } from 'react'
import { useGetProfileInfo, useUpdateProfileInfo } from '../../hook/user'
import Button from '../Button'
import CheckBox from '../Checkbox'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'

export default function TwoStepVerification() {

    const getProfile = useGetProfileInfo()
    const updateProfile = useUpdateProfileInfo()

    const formik = useFormik({
        initialValues: {
            email_authentication: false,
            sms_authentication: false,
        },
        onSubmit: (values) => {
            updateProfile.mutateAsync(values).then((_) => {
                toast.success('The operation done successfully')
            }).catch((err) => {
                toast.error(err.response?.data?.message)
            })
        }
    })

    useEffect(() => {
      
        if(getProfile.data) {
            formik.setFieldValue('email_authentication', getProfile.data?.email_authentication)
            formik.setFieldValue('sms_authentication', getProfile.data?.sms_authentication)
        }

    }, [getProfile.data])

    return (
        <div className='p-5'>
            <div className='text-xs'>
                Two-Step Varification Via
            </div>
            <div className='mt-2 h-12 px-4 border border-border rounded-md flex justify-between items-center'>
                <div className='text-sm'>
                    By Email
                </div>
                <CheckBox
                    checked={formik.values.email_authentication}
                    onChange={(value) => formik.setFieldValue('email_authentication', value)}
                />
            </div>
            <div className='mt-2 h-12 px-4 border border-border rounded-md flex justify-between items-center'>
                <div className='text-sm'>
                    By SMS
                </div>
                <CheckBox
                    checked={formik.values.sms_authentication}
                    onChange={(value) => formik.setFieldValue('sms_authentication', value)}
                />
            </div>

            <div className='mt-8'>
                <Button
                    title='Save Changes'
                    onClick={formik.handleSubmit}
                    isLoading={updateProfile.isLoading}
                />
            </div>
        </div>
    )
}
