import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import * as api from '../../api/user'
import { AnswerSymptomObjectInterface, ChangePasswordInterface, DeleteLearnerDocumentInterface, UpdateLearnerInfoInterface, UpdateParentInfoInterface } from "../../interface/Profile"
import { STALETIME } from "../../config/const"

export const useGetDocuments = () => {
    return useQuery(['documents'], () => api.GetDocuments(), {
        staleTime: STALETIME,
        retry: false
    })
}

export const useUploadDocuments = () => {
    return useMutation((variables: any) => api.UploadDocuments(variables))
}

export const useDeleteDocuments = () => {
    return useMutation((id: string) => api.DeleteDocuments(id))
}

export const useGetProfileInfo = () => {
    return useQuery(['profile'], () => api.GetProfileInfo(), {
        staleTime: STALETIME,
        retry: false
    })
}

export const useLogOutProfile = () => {
    return useMutation(_ => api.LogoutProfile())
}


export const useChangePassword = () => {
    return useMutation((variables: ChangePasswordInterface) => api.ChangePassword(variables))
}

export const useUpdateProfileInfo = () => {
    const queryClient = useQueryClient()
    return useMutation((variables: any) => api.UpdateProfile(variables), {
        onSuccess: () => {
            queryClient.refetchQueries(['profile'])
        }
    })
}

export const useGetNotifications = (enabled:boolean) => {
    return useQuery(['notifications'], () => api.GetNotifications(), {
        retry: false,
        staleTime: STALETIME,
        enabled: enabled,
    })
}

export const useWipeNotifications = () => {
    return useMutation((variables: any) => api.WipeNotifications(variables))
}

export const useGetNewPatients = (offset?:number) => {
    return useQuery(['new_patient', offset], () => api.GetNewPatients(offset), {
        retry: false,
        staleTime: STALETIME,
    })
}

// export const usetGetDocumentLearnerBySlug = (slug?:string) => {
//     return useQuery(['learner_document'], () => api.GetLearnerDocumentBySlug(slug + ''), {
//         staleTime: STALETIME,
//         retry: false,
//         enabled: !!slug
//     })
// }