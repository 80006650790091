import DownloadIcon from '../../assets/images/download.svg'
import DeleteIcon from '../../assets/images/delete.svg'
import UploadBox from '../UploadBox'
import { useState } from 'react'
import Button from '../Button'
import CheckBox from '../Checkbox'

interface Props {
    changeStep: (value: number) => void,
}

export default function Documents(props: Props) {

    const [resumeFiles, setResumeFiles] = useState<File[]>([])

    const resumeDelete = (index: number) => {
        const resumes = [...resumeFiles]
        resumes.splice(index, 1)
        setResumeFiles(resumes)
    }

    return (
        <div className='p-5'>
            <div className='text-xs'>
                Resume
            </div>
            <div>
                <div className='mt-3 h-12 px-4 bg-third rounded-md flex justify-between items-center'>
                    <div className='text-sm font-medium text-primary'>
                        Resume.pdf
                    </div>
                    <div className='flex'>
                        <img src={DownloadIcon} />
                        <img className='ml-4' src={DeleteIcon} />
                    </div>
                </div>

                {
                    resumeFiles.map((item: File, index: number) => {
                        return (
                            <div key={index} className='mt-2 h-12 px-4 bg-third rounded-md flex justify-between items-center'>
                                <div className='text-sm font-medium text-primary'>
                                    {item.name}
                                </div>
                                <div className='flex'>
                                    <img src={DownloadIcon} />
                                    <img onClick={() => resumeDelete(index)} className='ml-4' src={DeleteIcon} />
                                </div>
                            </div>
                        )
                    })
                }

                <div className='mt-2'>
                    <UploadBox
                        placeholder='+ Choose File'
                        readonly
                        onChange={(file) => setResumeFiles([
                            ...resumeFiles,
                            file
                        ])}
                    />
                </div>

            </div>

            <div className='text-xs mt-4'>
                degree of education
            </div>

            <div className='mt-3'>
                <UploadBox
                    placeholder='Choose File'
                />
            </div>

            <div className='text-xs mt-4'>
                Other Documents
            </div>

            <div className='mt-3'>
                <UploadBox
                    placeholder='Choose File'
                />
            </div>

            <div className='mt-3'>
                <div className='flex gap-2'>
                    <CheckBox
                        checked={true}
                        onChange={() => null}
                    />
                    <div className='text-xs'>
                        I confirm the authenticity of the attached documents. If the authenticity of the attached documents is not verified, I will take responsibility for the consequences
                    </div>
                </div>

                <div className='flex mt-2 gap-2'>
                    <CheckBox
                        checked={true}
                        onChange={() => null}
                    />
                    <div className='text-xs'>
                    I agree with the terms and conditions
                    </div>
                </div>
            </div>

            <div className='mt-8 gap-5 flex justify-between'>
                <Button
                    title='Previous Step'
                    className='bg-transparent border border-primary'
                    textColor='#273D8B'
                    onClick={() => props.changeStep(1)}
                />
                <Button
                    title='Next Step'
                    onClick={() => props.changeStep(3)}
                />
            </div>

        </div>
    )
}
