import axios from '../../config/axios'
import {MeetingSubmitInterface} from '../../interface/Patients'

export const GetForms = async () => {
    const {data} = await axios.get(`/v1/static/form/`)
    return data
}

export const GetParentsList = async (offset: number = 0, search?: string, queryText?:string) => {
    let query = ``
    if (search && search !== '') {
        query += `&search=${search}`
    }
    const {data} = await axios.get(`/v1/receptionist/parents/?offset=${offset}${query}${queryText ? queryText : ``}`)
    return data
}

export const GetParentDetail = async (parentId: string) => {
    const {data} = await axios.get(`/v1/receptionist/parents/${parentId}/`)
    return data
}

export const GetEvaluators = async () => {
    const {data} = await axios.get(`/v1/receptionist/parents/evaluator_list/`)
    return data
}

export const GetReceptionists = async () => {
    const {data} = await axios.get(`/v1/receptionist/parents/receptionist_list/`)
    return data
}


export const ParentSubmit = async (parentId: string, params: MeetingSubmitInterface) => {
    const {data} = await axios.post(`/v1/receptionist/parents/${parentId}/submit/`, params)
    return data
}

export const ParentDeactivate = async (parentId: string,) => {
    const {data} = await axios.post(`/v1/receptionist/parents/${parentId}/deactivate_account/`)
    return data
}

// Meetings

export const GetMeetingsList = async (offset: number = 0) => {
    const {data} = await axios.get(`/v1/user/meetings/?offset=${offset}`)
    return data
}

export const GetMeetingDetail = async (meetingId: string) => {
    const {data} = await axios.get(`/v1/user/meetings/${meetingId}/`)
    return data
}

export const CancelMeeting = async (meetingId: string) => {
    const {data} = await axios.post(`/v1/user/meetings/${meetingId}/cancel_meeting/`)
    return data
}

export const StartMeeting = async (meetingId: string) => {
    const {data} = await axios.post(`/v1/user/meetings/${meetingId}/start_meeting/`)
    return data
}

export const SubmitMeeting = async (meetingId: string, params:any) => {
    const {data} = await axios.post(`/v1/user/meetings/${meetingId}/submit_meeting/`, params)
    return data
}



