import Logo from '../../assets/images/logo.svg'
import EmailIcon from '../../assets/images/email.svg'
import LockIcon from '../../assets/images/lock.svg'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Auth } from '../../enums/Enums'
import { useNavigate } from 'react-router-dom'
import { LoginCheckCodeInterface, LoginCheckPasswordInterface } from '../../interface/Auth'
import { useLoginCheckCode, useLoginCheckPassword } from '../../hook/auth'
import { toast } from 'react-toastify'
import { Fragment, useState } from 'react'
import PinInput from 'react-pin-input'

export default function Login() {

    const navigate = useNavigate()
    const loginCheckPassword = useLoginCheckPassword()
    const loginCheckCode = useLoginCheckCode()
    const [randomkey, setRandomkey] = useState('')
    const [step, setStep] = useState(1)
    const [code, setCode] = useState('')

    const formik = useFormik<LoginCheckPasswordInterface>({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string().email()
                .required(),
            password: Yup.string()
                .required(),
        }),
        onSubmit: (values) => {
            loginCheckPassword.mutateAsync(values).then((res) => {
                console.log('res', res)
                if (res.token_info) {
                    localStorage.setItem(Auth.token_name, res.token_info.token)
                    window.location.href = '/'
                } else if (res.random_number) {
                    setRandomkey(res.random_number.key)
                    setStep(2)
                }
            }).catch((err) => {
                toast.error(err.response?.data?.message)
            })
        }
    })

    const handleCheckCode = () => {
        if(code.length === 5) {

            const params: LoginCheckCodeInterface = {
                random_key: randomkey,
                random_code: code
            }
            loginCheckCode.mutateAsync(params).then((res) => {
                toast.success('You have successfully logged in')
                localStorage.setItem(Auth.token_name, res?.token_info?.token)
                window.location.href = '/'
            }).catch((err) => {
                toast.error(err?.response?.data?.message)
            })

        } else {
            toast.error('Please enter the verification code completely')
        }
    }

    return (
        <div className="flex w-full min-h-full">
            <div className="flex-1 hidden lg:block bg-red-300 back_left_side bg-cover relative">
                <div className="absolute bottom-7 left-9 flex items-center font-medium">
                    <img alt='' src={Logo} />
                    <div className='ml-3 text-white'>
                        Autism Platform
                    </div>
                </div>
            </div>
            <div className="flex-1 w-full sm:w-[600px] flex h-screen flex-col justify-center">
                <div className='text-2xl text-[#343434] text-center font-medium'>
                    Welcome
                </div>

                {
                    step === 1 ?
                        <Fragment>
                            <div className='mt-16 w-full px-4 sm:px-0 sm:w-[358px] mx-auto'>
                                <Input
                                    placeholder='Email'
                                    icon={EmailIcon}
                                    name='username'
                                    onChange={formik.handleChange}
                                    error_text={formik.touched.username && formik.errors.username ? formik.errors.username : ''}
                                />
                                <Input
                                    placeholder='Password'
                                    icon={LockIcon}
                                    className='mt-4'
                                    type='password'
                                    onChange={formik.handleChange}
                                    name='password'
                                    error_text={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
                                />

                                <div className='mt-4 text-right text-sm text-primary'>
                                    Forgot password?
                                </div>

                                <div className='mt-8'>
                                    <Button
                                        title='Log in'
                                        onClick={formik.handleSubmit}
                                        isLoading={loginCheckPassword.isLoading}
                                    />
                                </div>
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            <div className='mt-16 w-full px-4 sm:px-0 sm:w-[358px] mx-auto'>
                                <div>
                                    <PinInput
                                        length={5}
                                        initialValue=""
                                        onChange={(value, index) => { setCode(value) }}
                                        type="numeric"
                                        inputMode="number"
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                        inputStyle={{ background: '#F7F7FD', border: 'none', width: 65, height: 65, borderRadius: 10, fontSize: 20 }}
                                        inputFocusStyle={{ borderColor: 'blue' }}
                                        onComplete={(value, index) => { }}
                                        autoSelect={true}
                                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                    />
                                </div>

                                <div className='mt-8'>
                                    <Button
                                        title='Submit'
                                        onClick={handleCheckCode}
                                        isLoading={loginCheckCode.isLoading}
                                    />
                                </div>
                            </div>
                        </Fragment>
                }
            </div>
        </div>
    )
}
