import { useQuery } from '@tanstack/react-query'
import * as api from '../../api/therapist'
import { STALETIME } from '../../config/const'

export const useGetTherapiistsList = (offset: number = 0, search?: string) => {
    return useQuery(["therapist", offset, search], () => api.GetTherapiistsList(offset, search), {
        retry: false,
    })
}

export const useGetTherapistTime = (therapist_id:any, month:any, year:any) => {
    return useQuery(["therapist_time", therapist_id, month, year], () => api.GetTherapistTime(therapist_id, month, year), {
        retry: false,
        staleTime: STALETIME,
        enabled: !!therapist_id && !!month && !!year,
    })
}

export const useGetTherapistDetail = (therapist_id:any) => {
    return useQuery(["therapist_detail", therapist_id], () => api.GetTherapistDetail(therapist_id), {
        retry: false,
        staleTime: STALETIME,
        enabled: !!therapist_id,
    })
}