import axios from '../../config/axios'
import { LoginCheckCodeInterface, LoginCheckPasswordInterface } from '../../interface/Auth'

export const LoginCheckPassword = async (params:LoginCheckPasswordInterface) => {
    const { data } = await axios.post('/v1/receptionist/login/check_password/', params)
    return data
}

export const LoginCheckCode = async (params:LoginCheckCodeInterface) => {
    const { data } = await axios.post('/v1/receptionist/login/check_code/', params)
    return data
}