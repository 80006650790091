import Input from '../../components/Input'
import Button from '../../components/Button'
import SearchIcon from '../../assets/images/search.svg'
import ChildrenImage from '../../assets/images/children.png'
import CalenderImage from '../../assets/images/calender.svg'
import CheckBox from '../../components/Checkbox'
import { useEffect, useState } from 'react'
import ModalSendNewMessage from '../../components/messages/ModalSendNewMessage'
import ModalConversation from '../../components/messages/ModalConversation'
import { useGetConversations } from '../../hook/messages'
import PageLoading from '../../components/PageLoading'
import moment from 'moment'
import Pagination from '../../components/Pagination'


export default function List() {

    const [checkedAll, setCheckedAll] = useState<boolean>(false)
    const [showModalSendMessage, setShowModalSendMessage] = useState<boolean>(false)
    const [showModalConversation, setShowModalConversation] = useState<boolean>(false)
    const [offset, setOffset] = useState<number>(0)
    const [search, setSearch] = useState('')
    const [search2, setSearch2] = useState<string>('')
    const [page, setPage] = useState(1)
    const [conversationId, setConversationId] = useState<any>()
    const getConversations = useGetConversations(offset, search)

    const handleChangePage = (pageNumber: number) => {
        var p = pageNumber - 1
        setPage(pageNumber)
        setOffset(p * 20)
    }

    useEffect(() => {
        const timeout = setTimeout(() => {

            setPage(1)
            setOffset(0)
            setSearch(search2)

        }, 1500)

        return () => clearTimeout(timeout)


    }, [search2])

    const handleShowConversation = (coonversationId: string) => {
        setConversationId(coonversationId)
        setShowModalConversation(true)
    }

    return (
        <div className='py-4'>
            <div className='px-6'>
                <div className='text-center md:text-left font-medium text-sm'>
                    Messages
                </div>

                <div className='mt-3 md:flex-row flex-col items-center flex md:justify-between w-full'>
                    <div className='flex mt-2 md:mt-0 flex-col sm:flex-row'>
                        <div className='relative w-52'>
                            <Input value={search2} onChange={(e) => setSearch2(e.target.value)} classNameInput='text-xs px-3' placeholder='Search Messages...' />
                            <img src={SearchIcon} className='absolute right-3 top-0 bottom-0 my-auto' />
                        </div>
                    </div>
                    <div className='w-[199px] mt-4 md:mt-0'>
                        <Button
                            title='Start a new conversation'
                            onClick={() => setShowModalSendMessage(true)}
                        />
                    </div>

                </div>

                {
                    getConversations.isLoading ?
                        <PageLoading />
                        :
                        <div className="relative overflow-x-auto mt-6">
                            <table className="w-full text-xs ">
                                <thead className='thead'>
                                    <tr>
                                        <td scope="col" className='td w-11'>
                                            <CheckBox
                                                checked={checkedAll}
                                                onChange={(value) => setCheckedAll(value)}
                                            />
                                        </td>
                                        <td scope="col" className='td'>
                                            Conversation
                                        </td>
                                        <td scope="col" className='td'>
                                            last Message
                                        </td>
                                        <td scope="col" className='td'>
                                            Last Message’s Date
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        getConversations.data?.results?.map((item: any) => {
                                            return (
                                                <tr key={item.id} onClick={() => handleShowConversation(item.id)} className={`border-b ${item?.last_message?.is_seen ? '' : 'bg-fifth'}`}>
                                                    <td scope="col" className='td w-11'>
                                                        <CheckBox
                                                            checked={checkedAll}
                                                            onChange={(value) => setCheckedAll(value)}
                                                        />
                                                    </td>
                                                    <td scope="row" className="td">
                                                        <div className='flex items-center'>
                                                            <img src={item.contact.avatar} className='w-6 h-6 rounded-full object-cover' />
                                                            <div className='ml-2'>{item.contact.first_name + ' ' + item.contact.last_name}</div>
                                                        </div>
                                                    </td>
                                                    <td scope="row" className="td font-medium">
                                                        {item.last_message?.message}
                                                    </td>
                                                    <td scope="row" className="td">
                                                        {
                                                            item.last_message?.insert_dt &&
                                                            <div className='flex'>
                                                                <img src={CalenderImage} />
                                                                <div className='ml-2'>
                                                                    {moment(item.last_message.insert_dt).format('lll')}
                                                                </div>
                                                            </div>
                                                        }

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                }

                {
                    getConversations.data?.count ?
                        <Pagination
                            currentPage={page}
                            onChange={(pageNumber) => handleChangePage(pageNumber)}
                            pageSize={20}
                            total={getConversations.data?.count}
                        />
                        : null
                }
            </div>
            <ModalSendNewMessage
                open={showModalSendMessage}
                close={() => setShowModalSendMessage(false)}
            />
            <ModalConversation
                open={showModalConversation}
                close={() => {
                    setShowModalConversation(false)
                    setConversationId('')
                }}
                conversation_id={conversationId}
            />
        </div>
    )
}
