import { useState } from 'react'
import GeneralInformation from '../../components/settings/GeneralInformation'
import Documents from '../../components/settings/Documents'
import AccountSetting from '../../components/settings/AccountSetting'
import Notifications from '../../components/settings/Notifications'
import TwoStepVerification from '../../components/settings/TwoStepVerifications'

export default function Settings() {

    const [activeTab, setActiveTab] = useState<number>(1)

    return (
        <div>
            <div className='w-full min-h-[51px] flex-wrap lg:h-[51px] border-b mb-3 lg:mb-0 border-border flex items-center text-sm'>
                <div onClick={() => setActiveTab(1)} className={`px-4 flex-1 whitespace-nowrap h-[51px] lg:h-full text-center justify-center min-w-[100%] lg:min-w-max ${activeTab === 1 && 'bg-secondary text-primary font-medium rounded-t-lg'} transition-all flex items-center`}>
                    General Information
                </div>
                <div onClick={() => setActiveTab(2)} className={`px-4 flex-1 whitespace-nowrap h-[51px] lg:h-full text-center justify-center min-w-[100%] lg:min-w-max ${activeTab === 2 && 'bg-secondary text-primary font-medium rounded-t-lg'} transition-all flex items-center`}>
                    Documents
                </div>
                <div onClick={() => setActiveTab(3)} className={`px-4 flex-1 whitespace-nowrap h-[51px] lg:h-full text-center justify-center min-w-[100%] lg:min-w-max ${activeTab === 3 && 'bg-secondary text-primary font-medium rounded-t-lg'} transition-all flex items-center`}>
                    Account Settings
                </div>
                <div onClick={() => setActiveTab(4)} className={`px-4 flex-1 whitespace-nowrap h-[51px] lg:h-full text-center justify-center min-w-[100%] lg:min-w-max ${activeTab === 4 && 'bg-secondary text-primary font-medium rounded-t-lg'} transition-all flex items-center`}>
                    Notifications
                </div>
                <div onClick={() => setActiveTab(5)} className={`px-4 flex-1 whitespace-nowrap h-[51px] lg:h-full text-center justify-center min-w-[100%] lg:min-w-max ${activeTab === 5 && 'bg-secondary text-primary font-medium rounded-t-lg'} transition-all flex items-center`}>
                    Two-Step Varification
                </div>
            </div>
            <div className='md:max-w-xs'>
                {
                    activeTab === 1 ?
                        <GeneralInformation />
                        : activeTab === 2 ?
                            <Documents />
                        : activeTab === 3 ?
                            <AccountSetting />
                        : activeTab === 4 ?
                            <Notifications />
                            : <TwoStepVerification />
                }
            </div>
        </div>
    )
}
