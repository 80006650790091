import moment from 'moment'
import childrenImage from '../assets/images/children.png'
import { useGetNotifications, useWipeNotifications } from '../hook/user'
import Button from './Button'
import NotificationItem from './NotificationItem'
import PageLoading from './PageLoading'
import { toast } from 'react-toastify'

interface Props {
    open: boolean,
    close: () => void,
}

export default function ModalNotification(props: Props) {

    const getNotifications = useGetNotifications(props.open)
    const wipteNotifications = useWipeNotifications()

    const onWipe = () => {
        const array: string[] = []
        getNotifications.data?.map((item: any) => {
            array.push(item.id)
        })
        const params = {
            notification_list: [...array]
        }
        wipteNotifications.mutateAsync(params).then((res) => {
            toast.success('The operation was successful')
            window.location.reload()
        }).catch((err) => {
            toast.error(err?.response?.data?.message)
        })
    }

    return (
        <>
            <div onClick={props.close} className={`${!props.open ? 'hidden invisible' : ''} visible transition-all bg-black_opacity_40 fixed w-full h-full z-30`}>
            </div>
            <div className={`fixed top-0 overflow-y-auto right-0 z-40 w-[280px] px-5 py-5 h-full transition-all duration-500 transform bg-white ${props.open ? 'translate-x-0' : 'translate-x-full'}`}>
                <div className="text-sm font-medium">
                    Notifications
                </div>

                {
                    getNotifications.isLoading ?
                        <PageLoading />
                        :
                        <div className='relative'>

                            <div className='mb-[114px]'>
                                {
                                    getNotifications.data?.map((item: any) => {
                                        return (
                                            <NotificationItem
                                                key={item.id}
                                                date={moment(item.insert_dt).format('YYYY-MM-DD')}
                                                time={moment(item.insert_dt).format('h:m A')}
                                                title={item.subject}
                                                description={item.message}
                                            />
                                        )
                                    })
                                }
                            </div>

                            <div className='fixed bottom-0 w-[230px] justify-center bg-white h-[100px] flex items-center '>
                                <Button
                                    onClick={onWipe}
                                    title='Wipe Notifications'
                                    isLoading={wipteNotifications.isLoading}
                                />
                            </div>
                        </div>
                }
            </div>

        </>
    )
}
