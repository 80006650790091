import Logo from '../../assets/images/logo.svg'
import EmailIcon from '../../assets/images/email.svg'
import LockIcon from '../../assets/images/lock.svg'
import LineIcon from '../../assets/images/line.svg'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { Colors } from '../../enums/Enums'
import { Fragment, useState } from 'react'
import Information from '../../components/register/Information'
import Documents from '../../components/register/Documents'
import Verification from '../../components/register/Verification'
import { useNavigate } from 'react-router-dom'

export default function Login() {

  const [step, setStep] = useState<number>(0)

  const navigate = useNavigate()

  return (
    <div className="flex w-full min-h-full">
      <div className="flex-1 hidden lg:block back_left_side bg-cover relative">
        <div className="absolute bottom-7 left-9 flex items-center font-medium">
          <img alt='' src={Logo} />
          <div className='ml-3 text-white'>
            Autism Platform
          </div>
        </div>
      </div>
      <div className="flex-1 w-full sm:w-[600px] flex min-h-screen py-5 flex-col justify-center">
        <div className={`text-2xl text-[#343434] text-center font-medium ${step !== 0 && 'hidden'}`}>
          Register
        </div>

        {
          step !== 0 &&
          <Fragment>
            <div className='sm:hidden flex justify-center'>
              <div className={`text-center ${step < 1 && 'opacity-30'} flex flex-col items-center`}>
                <div className='w-16 h-16 text-2xl rounded-full flex justify-center items-center bg-primary font-medium text-white'>
                  {step}
                </div>
                <div className='mt-2'>
                  {
                    step === 1 ?
                      'Information'
                      :
                      step === 2 ?
                        'Documents'
                        :
                        step === 3 ?
                          'Varification'
                          : 'Complete'
                  }
                </div>
              </div>
            </div>
            <div className='flex-col items-center sm:flex hidden'>
              <div className='text-2xl text-[#343434] font-medium'>
                Please Follow The Steps
              </div>
              <div className='flex mt-6 gap-1'>

                <div className={`text-center ${step < 1 && 'opacity-30'} flex flex-col items-center`}>
                  <div className='w-8 h-8 rounded-full flex justify-center items-center bg-primary font-medium text-white'>
                    1
                  </div>
                  <div className='mt-2 text-xs'>
                    Information
                  </div>
                </div>

                <img src={LineIcon} />

                <div className={`text-center ${step < 2 && 'opacity-30'} flex flex-col items-center`}>
                  <div className='w-8 h-8 rounded-full flex justify-center items-center bg-primary font-medium text-white'>
                    2
                  </div>
                  <div className='mt-2 text-xs'>
                    Documents
                  </div>
                </div>

                <img src={LineIcon} />

                <div className={`text-center ${step < 3 && 'opacity-30'} flex flex-col items-center`}>
                  <div className='w-8 h-8 rounded-full flex justify-center items-center bg-primary font-medium text-white'>
                    3
                  </div>
                  <div className='mt-2 text-xs'>
                    Varification
                  </div>
                </div>

                <img src={LineIcon} />

                <div className={`text-center ${step < 4 && 'opacity-30'} flex flex-col items-center`}>
                  <div className='w-8 h-8 rounded-full flex justify-center items-center bg-primary font-medium text-white'>
                    4
                  </div>
                  <div className='mt-2 text-xs'>
                    Complete
                  </div>
                </div>

              </div>
            </div>
          </Fragment>
        }

        <div className='sm:mt-16 mt-4 w-full px-4 sm:px-0 sm:w-[358px] mx-auto'>

          {
            step === 0 ?
              <Fragment>
                <Input
                  placeholder='Username'
                  icon={EmailIcon}
                />
                <Input
                  placeholder='Email'
                  icon={EmailIcon}
                  className='mt-4'
                />

                <Input
                  placeholder='Password'
                  icon={LockIcon}
                  className='mt-4'
                  type='password'
                />

                <Input
                  placeholder='Password'
                  icon={LockIcon}
                  className='mt-4'
                  type='Confirm Password'
                />

                <div className='mt-8'>
                  <Button
                    title='Register'
                    onClick={() => setStep(1)}
                  />
                </div>

                <div className='mt-6 text-[#828282] text-sm text-center'>
                  Have an Account?
                </div>

                <div className='mt-8'>
                  <Button
                    title='Log In'
                    className='bg-white text-primary border border-primary'
                    bg='white'
                    textColor={Colors.PRIMARY}
                  />
                </div>
              </Fragment>
              : step === 1 ?
                <Information changeStep={(value) => setStep(value)} />
                : step === 2 ?
                  <Documents changeStep={(value) => setStep(value)} />
                  : step === 3 ?
                    <Verification changeStep={(value) => setStep(value)} />
                    :
                    <div className='text-center'>
                      <div className='font-medium text-[#343434]'>
                        your Tracking Code is <span className='text-primary'>3465399</span>.
                      </div>
                      <div className='mt-10'>
                        After verification and document assessment, your username and  password will be sent to your phone number and email.
                      </div>

                      <div className='mt-20'>
                        <Button
                          title='Back To Main Page'
                          onClick={() => navigate('/')}
                        />
                      </div>
                    </div>
          }

        </div>
      </div>
    </div>
  )
}
