import moment from "moment/moment";
import ReactSelect from "react-select";
import CheckBox from "../Checkbox";
import Input from "../Input";
import Select from "../Select";
import Button from "../Button";
import { Colors } from "../../enums/Enums";
import { useCallback, useEffect, useState } from "react";
import {
    useCancelMeeting,
    useParentDeactivate,
    useGetEvaluators,
    useGetReceptionists,
    useGetForms,
    useGetParentDetail,
    useParentSubmit
} from "../../hook/patients";
import ModalConfirm from "../ModalConfirm";
import { MeetingSubmitInterface } from "../../interface/Patients";
import { toast } from "react-toastify";

interface FormProps {
    parentId: string,
    activeForm: string[],
    setActiveForm: (value: string[]) => void,
}

function ParentFormInformation(props: FormProps) {
    const getForms = useGetForms()
    const getParentDetail = useGetParentDetail(props.parentId)
    const [formViewData, setFormViewData] = useState<any[]>([])

    const handleChangeActiveForm = useCallback((value: boolean, formId: string) => {
        if (value) {
            props.setActiveForm([
                ...props.activeForm,
                formId
            ])
        } else {
            const array = [...props.activeForm]
            const index = array.findIndex(o => o === formId)
            array.splice(index, 1)
            props.setActiveForm(array)
        }
    }, [props.activeForm, props.setActiveForm])

    useEffect(() => {
        const disableForms: string[] = getParentDetail.data?.assign_forms?.map((item: any) => item.form.id)
        setFormViewData(getForms.data?.map((item: any) => {
            return (
                <div key={item.id} className="flex mt-2">
                    <CheckBox
                        onChange={(value) => handleChangeActiveForm(value, item.id)}
                        checked={disableForms.includes(item.id) || props.activeForm.includes(item.id)}
                        disabled={disableForms.includes(item.id)}
                    />
                    <div className="flex">
                        <div className="ml-2">
                            {item.name}
                        </div>
                    </div>
                </div>
            )
        }))
    }, [getForms.data, props.activeForm])

    return <>
        {formViewData}
    </>
}

interface Props {
    parentId: string,
}


export default function ParentInformation(props: Props) {
    const getParentDetail = useGetParentDetail(props.parentId)
    const getEvaluators = useGetEvaluators()
    const getReceptionists = useGetReceptionists()
    const [activeTab, setActiveTab] = useState(1)
    const [typeModalConfirm, setTypeModalConfirm] = useState<string | null>(null)

    const [evaluators, setEvaluators] = useState<any[]>([])
    const [receptionists, setReceptionists] = useState<any[]>([])

    const cancelMeeting = useCancelMeeting()
    const parentDeactivate = useParentDeactivate()
    const parentSubmit = useParentSubmit(props.parentId)

    const [status, setStatus] = useState<number>()
    const [activeForm, setActiveForm] = useState<string[]>([])
    const [assignReceptionistId, setAssignReceptionistId] = useState<string>('')
    const [assignEvaluatorId, setAssignEvaluatorId] = useState<string>('')
    const [description, setDescription] = useState<string>('')

    const onSubmit = () => {

        const params: MeetingSubmitInterface = {
            receptionist_description: description,
            forms_list: activeForm,
            parent_status: status ? status : null,
            evaluator: assignEvaluatorId !== '' ? assignEvaluatorId : null,
            receptionist: assignReceptionistId !== '' ? assignReceptionistId : null
        }

        parentSubmit.mutateAsync(params).then((_) => {
            toast.success('Operation done successfully')
            window.location.reload()
        }).catch((err) => {
            toast.error(err.response?.data?.message)
        })
    }


    const handleConfirmSubmit = (type: string) => {
        if (type === "cancel") {
            cancelMeeting.mutateAsync(getParentDetail.data?.next_meeting?.id).then((_) => {
                toast.success("Operation done successfully")
                window.open("/patients", "_self")
            }).catch((err) => {
                toast.error(err.response?.data?.message)
            })
        } else if (type === "deactivate") {
            parentDeactivate.mutateAsync(props.parentId).then((_) => {
                toast.success("Operation done successfully")
                window.open("/patients", "_self")
            }).catch((err) => {
                toast.error(err.response?.data?.message)
            })
        }
    }


    useEffect(() => {
        setReceptionists(getReceptionists.data?.map((item: any) => (
            {
                label: item.__str__,
                value: item.id
            }
        )))
    }, [getReceptionists.data])

    useEffect(() => {
        setEvaluators(getEvaluators.data?.map((item: any) => (
            {
                label: item.__str__,
                value: item.id
            }
        )))
    }, [getEvaluators.data])

    useEffect(() => {
        setDescription(getParentDetail.data?.parent?.receptionist_description)
    }, [getParentDetail.data])

    return (
        <>
            <div className="flex md:flex-row flex-col">
                <div className="flex-1 border-r-0 md:border-r border-border  md:px-4 pb-3">
                    <div className="mt-4">
                        <div
                            className='w-full min-h-[51px] flex-wrap md:h-[51px] border-b mb-3 sm:mb-0 border-border flex items-center text-sm'>
                            <div onClick={() => setActiveTab(1)}
                                className={`px-4 flex-1 whitespace-nowrap h-[51px] lg:h-full text-center justify-center min-w-[100%] md:min-w-max ${activeTab === 1 && 'bg-secondary text-primary font-medium rounded-t-lg'} transition-all flex items-center`}>
                                Symptoms
                            </div>
                            <div onClick={() => setActiveTab(2)}
                                className={`px-4 flex-1 whitespace-nowrap h-[51px] lg:h-full text-center justify-center min-w-[100%] md:min-w-max ${activeTab === 2 && 'bg-secondary text-primary font-medium rounded-t-lg'} transition-all flex items-center`}>
                                Informations
                            </div>
                            <div onClick={() => setActiveTab(3)}
                                className={`px-4 flex-1 whitespace-nowrap h-[51px] lg:h-full text-center justify-center min-w-[100%] md:min-w-max ${activeTab === 3 && 'bg-secondary text-primary font-medium rounded-t-lg'} transition-all flex items-center`}>
                                Description
                            </div>
                        </div>
                        <div className="max-h-72 overflow-y-auto">
                            {
                                activeTab === 1 ?
                                    getParentDetail.data?.symptom_answers?.map((item: any) => {
                                        return (
                                            <div key={item.id} className="py-3 border-b border-border">
                                                <div className="text-sm">
                                                    {item.symptom_question?.short_text}
                                                </div>
                                                <div className="mt-2 flex gap-2 items-center">
                                                    <div className="flex gap-1">
                                                        <div
                                                            style={{ background: item.text === 'Rarely' ? 'green' : item.text === 'Sometimes' ? '#FFCB44' : item.text === 'Often' ? '#FF8744' : item.text === 'Always' ? '#FF4444' : '#E8EDFF' }}
                                                            className="w-3 h-1 bg-[#E8EDFF]"></div>
                                                        <div
                                                            style={{ background: item.text === 'Sometimes' ? '#FFCB44' : item.text === 'Often' ? '#FF8744' : item.text === 'Always' ? '#FF4444' : '#E8EDFF' }}
                                                            className="w-3 h-1 bg-[#E8EDFF]"></div>
                                                        <div
                                                            style={{ background: item.text === 'Often' ? '#FF8744' : item.text === 'Always' ? '#FF4444' : '#E8EDFF' }}
                                                            className="w-3 h-1 bg-[#E8EDFF]"></div>
                                                        <div
                                                            style={{ background: item.text === 'Always' ? '#FF4444' : '#E8EDFF' }}
                                                            className="w-3 h-1 bg-[#E8EDFF]"></div>
                                                    </div>
                                                    <div className="text-xs">
                                                        {item.text}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    activeTab === 2 ?
                                        <div className="mt-3">
                                            <div className="mt-3 text-sm flex justify-between items-center">
                                                <div className="font-medium">
                                                    Patient Status
                                                </div>
                                                <div className="text-[#737896]">
                                                    <div style={{
                                                        background: getParentDetail.data?.parent?.parent_status?.background_color,
                                                        color: getParentDetail.data?.parent?.parent_status?.font_color
                                                    }} className={`ml-2 font-medium p-2 py-1 w-fit rounded-1`}>
                                                        {getParentDetail.data?.parent?.parent_status?.text}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="text-sm font-medium">
                                                Patient’s Information
                                            </div>

                                            <div className="mt-3 text-sm flex justify-between items-center">
                                                <div>
                                                    Name and Last Name
                                                </div>
                                                <div className="text-[#737896]">
                                                    {getParentDetail.data?.learner?.__str__}
                                                </div>
                                            </div>


                                            <div className="mt-3 text-sm flex justify-between items-center">
                                                <div>
                                                    Country
                                                </div>
                                                <div className="text-[#737896]">
                                                    {getParentDetail.data?.parent?.country_name}
                                                </div>
                                            </div>
                                            <div className="mt-3 text-sm flex justify-between items-center">
                                                <div>
                                                    Birth Order
                                                </div>
                                                <div className="text-[#737896]">
                                                    {getParentDetail.data?.learner?.birth_order}
                                                </div>
                                            </div>
                                            <div className="mt-3 text-sm flex justify-between items-center">
                                                <div>
                                                    Father’s birth date
                                                </div>
                                                <div className="text-[#737896]">
                                                    {moment(getParentDetail.data?.learner?.father_birth_day).format("YYYY")}
                                                </div>
                                            </div>
                                            <div className="mt-3 text-sm flex justify-between items-center">
                                                <div>
                                                    Mother’s birth date
                                                </div>
                                                <div className="text-[#737896]">
                                                    {moment(getParentDetail.data?.learner?.mother_birth_day).format("YYYY")}
                                                </div>
                                            </div>
                                            <div className="mt-3 text-sm flex justify-between items-center">
                                                <div>
                                                    Referer
                                                </div>
                                                <div className="text-[#737896]">
                                                    {getParentDetail.data?.parent?.referrer}
                                                </div>
                                            </div>

                                            <div className="mt-3 text-sm flex justify-between items-center">
                                                <div>
                                                    Selected Receptionist
                                                </div>
                                                <div className="text-[#737896]">
                                                    {getParentDetail.data?.parent?.receptionist?.__str__}
                                                </div>
                                            </div>

                                            {getParentDetail.data?.parent?.evaluator ?
                                                <div className="mt-3 text-sm flex justify-between items-center">
                                                    <div>
                                                        Selected Evaluator
                                                    </div>
                                                    <div className="text-[#737896]">
                                                        {getParentDetail.data?.parent?.evaluator?.__str__}
                                                    </div>
                                                </div> : null}

                                            <div className="mt-4 font-medium">
                                                Caretaker’s Information
                                            </div>

                                            <div className="mt-3 text-sm flex justify-between items-center">
                                                <div>
                                                    Phone Number
                                                </div>
                                                <div className="text-[#737896]">
                                                    {getParentDetail.data?.parent?.phone_number}
                                                </div>
                                            </div>

                                            <div className="mt-3 text-sm flex justify-between items-center">
                                                <div>
                                                    Relation with Patient
                                                </div>
                                                <div className="text-[#737896]">
                                                    {getParentDetail.data?.parent?.relation}
                                                </div>
                                            </div>
                                            <div className="mt-3 text-sm flex justify-between items-center">
                                                <div>
                                                    Name and Last Name
                                                </div>
                                                <div className="text-[#737896]">
                                                    {getParentDetail.data?.parent?.__str__}
                                                </div>
                                            </div>
                                            <div className="mt-3 text-sm flex justify-between items-center">
                                                <div>
                                                    Email
                                                </div>
                                                <div className="text-[#737896]">
                                                    {getParentDetail.data?.parent?.email}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="mt-3 text-sm">
                                            {getParentDetail.data?.parent?.description}
                                        </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="flex-1 text-sm pb-3 border-t border-border md:border-t-0">
                    <div className="max-w-sm md:px-4">
                        {
                            getParentDetail.data?.next_meeting ? <>
                                <div className="mt-3 font-medium">
                                    Session Information
                                </div>
                                <div className="mt-3 flex gap-6">
                                    <div>
                                        Session Type
                                    </div>
                                    <div className="text-[#737896] capitalize">
                                        {getParentDetail.data?.next_meeting?.meeting_type}
                                    </div>
                                </div>
                                <div className="mt-3 flex gap-6">
                                    <div>
                                        Session Date
                                    </div>
                                    <div className="flex text-[#737896]">
                                        <div>
                                            {moment(getParentDetail.data?.next_meeting?.start_time).format('MM/DD/YYYY HH:mm')}
                                        </div>
                                        <div onClick={() => setTypeModalConfirm('cancel')}
                                            className="text-primary ml-3 cursor-pointer">
                                            Cancel
                                        </div>
                                    </div>
                                </div>
                            </> : null
                        }


                        <div className="mt-5 font-medium">
                            Receptionist Assignment
                        </div>

                        <div className="mt-2">
                            <ReactSelect
                                isClearable
                                options={receptionists}
                                onChange={(newValue: any) => setAssignReceptionistId(newValue ? newValue.value : '')}
                                placeholder='Search Receptionist'
                            />
                        </div>

                        <div className="mt-5 font-medium">
                            Therapist Assignment
                        </div>

                        <div className="mt-2">
                            <ReactSelect
                                options={evaluators}
                                onChange={(newValue: any) => setAssignEvaluatorId(newValue ? newValue.value : '')}
                                placeholder='Search Therapist'
                                isClearable
                            />
                        </div>


                        <div className="mt-5 font-medium">
                            Activate Forms
                        </div>

                        <ParentFormInformation
                            parentId={props.parentId}
                            activeForm={activeForm}
                            setActiveForm={setActiveForm} />

                        <div className="mt-5 font-medium">
                            Description of the situation
                        </div>

                        <div className="mt-2">
                            <Input
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>

                    </div>
                </div>
            </div>

            <div
                className="pt-3 border-t border-border md:h-14 flex md:flex-row flex-col items-center px-4 justify-between">
                <div className="w-[211px]">
                    {/* <Select
                        items={[
                            {
                                id: 11,
                                text: 'Didn’t answer'
                            },
                            {
                                id: 2,
                                text: 'Waiting for Assessment'
                            },
                        ]}
                        placeholder="Change Status"
                        onChange={(id: any) => setStatus(id)}
                    /> */}
                </div>
                <div className="flex gap-2 md:mt-0 mt-3">
                    <div className="w-40">
                        <Button
                            title="Deactivate Account"
                            textColor={Colors.PRIMARY}
                            bg={Colors.SECONDARY}
                            onClick={() => setTypeModalConfirm("deactivate")}
                            isLoading={parentDeactivate.isLoading}
                        />
                    </div>
                    <div className="w-24">
                        <Button
                            title="Submit"
                            className="font-medium"
                            onClick={onSubmit}
                            isLoading={parentSubmit.isLoading}
                        />
                    </div>
                </div>
            </div>
            {
                typeModalConfirm &&
                <ModalConfirm
                    close={() => setTypeModalConfirm(null)}
                    submit={handleConfirmSubmit}
                    type={typeModalConfirm}
                    isLoading={cancelMeeting.isLoading || parentDeactivate.isLoading}
                />
            }
        </>
    )
}