type Props = {
    checked: boolean,
    onChange: (value: boolean) => void,
    disabled?: boolean
}

const CheckBox = (props: Props) => {
    return (
        <input disabled={props.disabled}
               onChange={(e) => props.onChange(e.target.checked)}
               checked={props.checked}
               type="checkbox" className='w-5 h-5 text-blue-600 bg-gray-100 rounded border-gray-300  '/>
    )
}

export default CheckBox