type Props = {
    onChange: (value:any) => void,
    name: string,
    value: number,
    checked?: boolean
}

const Radio = (props:Props) => {

    return (
        <input checked={props.checked} value={props.value} name={props.name} onChange={(e) => props.onChange(e.target.value)}  type="radio" className='w-5 h-5 text-blue-600 bg-gray-100 rounded border-gray-300  ' />
    )
}

export default Radio