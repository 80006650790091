import { Fragment, useCallback, useEffect, useState } from 'react'
import DoctorImage from '../../assets/images/doctor.webp'
import EditIcon from '../../assets/images/edit.svg'
import Input from '../Input'
import { useFormik } from 'formik'
import { useDropzone } from 'react-dropzone'
import Button from '../Button'
import { useGetProfileInfo, useUpdateProfileInfo } from '../../hook/user'
import CountryJson from '../../json/country.json'
import ReactSelect from 'react-select'
import PageLoading from '../PageLoading'
import { toast } from 'react-toastify'

export default function GeneralInformation() {

    const [fileSrc, setFileSrc] = useState<any>('')
    const [file, setFile] = useState<File>()
    const [country, setCountry] = useState<any>([])
    const [countryValue, setCountryValue] = useState<any>()
    const getProfile = useGetProfileInfo()
    const updateProfile = useUpdateProfileInfo()

    const formik = useFormik({
        initialValues: {
            first_name: 'Caroline',
            last_name: 'Molly',
            // phone: '+1239948732',
            email: 'CarolineMolly@gmail.com',
            country: 'US',
        },
        onSubmit: (values) => {
            const formData = new FormData()
            formData.append('first_name', values.first_name)
            formData.append('last_name', values.last_name)
            formData.append('email', values.email)
            formData.append('country', values.country)
            if (file) {
                formData.append('avatar', file)
            }
            updateProfile.mutateAsync(formData).then((_) => {
                toast.success('The operation done successfully')
            }).catch((err) => {
                toast.error(err.response?.data?.message)
            })
        }
    })

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const reader = new FileReader()

        reader.readAsDataURL(acceptedFiles[0]);
        reader.onloadend = function () {
            setFileSrc(reader.result)
        }

        setFile(acceptedFiles[0])

    }, [])
    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    useEffect(() => {

        if (getProfile.data) {
            const obj = CountryJson.find(o => o.code === getProfile.data.country)
            formik.setFieldValue('first_name', getProfile.data?.first_name)
            formik.setFieldValue('last_name', getProfile.data?.last_name)
            formik.setFieldValue('email', getProfile.data?.email)
            formik.setFieldValue('country', getProfile.data?.country)
            setFileSrc(getProfile.data?.avatar)
            setCountryValue({
                value: obj?.code + '#' + obj?.dial_code,
                label: obj?.flag + '     ' + obj?.name
            })
        }

    }, [getProfile.data])

    useEffect(() => {
        const array: any = []

        CountryJson.map((item) => {
            array.push({
                value: item.code + '#' + item.dial_code,
                label: item.flag + '     ' + item.name
            })
        })
        setCountry(array)
    }, [])

    const changeCountry = (value: any) => {
        setCountryValue(value)
        const value_split = value?.value?.split('#')
        formik.setFieldValue('country', value_split[0])
    }

    return (
        <div className='p-5'>
            {
                getProfile.isLoading ?
                    <PageLoading />
                    :
                    <Fragment>
                        <div className='text-xs'>
                            Change Picture
                        </div>
                        <div {...getRootProps()} className='relative w-16 h-16 mt-2 rounded-[8px] overflow-hidden'>
                            <input {...getInputProps()} />
                            <img src={fileSrc} className='w-full h-full object-cover' />
                            {
                                !fileSrc &&
                                <div className='absolute bg-black_opacity_40 w-full h-full top-0 flex justify-center items-center'>
                                    <img src={EditIcon} />
                                </div>
                            }
                        </div>

                        <div>
                            <Input
                                label='Email'
                                value={formik.values.email}
                                name='email'
                                onChange={formik.handleChange}
                                readonly
                            />
                            <Input
                                label='First Name'
                                value={formik.values.first_name}
                                name='first_name'
                                onChange={formik.handleChange}
                            />
                            <Input
                                label='Last name'
                                value={formik.values.last_name}
                                name='last_name'
                                onChange={formik.handleChange}
                            />

                            <div className={`mt-4`}>
                                <div className='font-medium'>
                                    Country
                                </div>
                                <div className='mt-2'>
                                    <ReactSelect value={countryValue} onChange={changeCountry} placeholder='Choose' isSearchable options={country} />
                                </div>
                            </div>

                            <div className='mt-8'>
                                <Button
                                    title='Save Changes'
                                    onClick={formik.handleSubmit}
                                    isLoading={updateProfile.isLoading}
                                />
                            </div>
                        </div>
                    </Fragment>
            }
        </div>
    )
}
