import PinInput from 'react-pin-input'
import Button from '../Button'

interface Props {
    changeStep: (value: number) => void,
}

export default function Verification(props: Props) {
    return (
        <div className="text-center">
            <div>
                We Sent Your Email A Varification Code
            </div>
            <div className="mt-3 text-sm">
                Enter the Code and Complete Your Sign Up
            </div>

            <div className='mt-10'>
                <PinInput
                    length={4}
                    initialValue=""
                    onChange={(value, index) => null}
                    type="numeric"
                    inputMode="number"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    inputStyle={{ borderColor: '#EEEEEE', width: 65, height: 65, borderRadius: 10, fontSize: 20 }}
                    inputFocusStyle={{ borderColor: '#EEEEEE' }}
                    onComplete={(value, index) => { }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
            </div>

            <div className='text-sm'>
                <div className='mt-8'>
                    Didn’t Receive the Code?
                </div>
                <div className='mt-4 text-primary font-normal'>
                    Send Again
                </div>
                <div className='mt-3 text-primary font-normal'>
                    Try Other Methods
                </div>
            </div>

            <div className='mt-8 gap-5 flex justify-between'>
                <Button
                    title='Previous Step'
                    className='bg-transparent border border-primary'
                    textColor='#273D8B'
                    onClick={() => props.changeStep(2)}
                />
                <Button
                    title='Next Step'
                    onClick={() => props.changeStep(4)}
                />
            </div>
        </div>
    )
}
