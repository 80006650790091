import './assets/fonts/pappins/all.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter, } from 'react-router-dom'
import Main from './pages/Main'
import { GlobalProvider } from './contexts/global'
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { Auth } from './enums/Enums'

export default function App() {

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error: any) => {
        if (error?.response?.status === 401) {
          localStorage.removeItem(Auth.token_name)
          window.location.href = '/auth/login'
        }
      },
    }),
  })

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <GlobalProvider>
          <Main />
          <ToastContainer position='top-center' limit={1} />
        </GlobalProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}
