type Props = {
    currentPage: number,
    total: number,
    pageSize: number,
    onChange: (page: number) => void
}

const Pagination = (props: Props) => {    

    return (
        <div className='px-4 my-4 flex md:justify-end justify-center'>
            <nav className="isolate inline-flex -space-x-px rounded-md" aria-label="Pagination">

               
                <a href="#" className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-sm font-medium  focus:z-20">
                    <span className="sr-only">Previous</span>
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                    </svg>
                </a>
                {
                    [...Array(Math.ceil(props.total / props.pageSize)).fill(0).map((_, i) => {
                        if (i + 1 === props.currentPage)
                            return (
                                <a key={i} onClick={() => props.onChange(i + 1)} href="#" aria-current="page" className="relative bg-border rounded-md z-10 inline-flex items-center px-4 py-2 text-sm  focus:z-20">{i + 1}</a>
                            )
                        else
                            return (
                                <a key={i} onClick={() => props.onChange(i + 1)} href="#" className="relative inline-flex items-centerte px-4 py-2 text-sm focus:z-20">{i + 1}</a>
                            )
                    })]
                }
                 <a href="#" className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-sm font-medium  focus:z-20">
                    <span className="sr-only">Next</span>
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                    </svg>
                </a>
            </nav>
        </div>
    )
}

export default Pagination