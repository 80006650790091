import { useEffect, useState } from 'react'
import Radio from '../Radio'
import moment from 'moment'

interface Props {
    data: any,
    date: any,
    onTimeSelected: (time_id:string) => void,
}

export default function PickAnHour(props: Props) {

    const [timeSelected, setTimeSelected] = useState<string>()

    const handleChangeTime = (value:string) => {
        setTimeSelected(value)
        props.onTimeSelected(value)
    }
    
    useEffect(() => {
      
        setTimeSelected(undefined)

    }, [props.date])
    

    if (props.data && props.date)
        return (
            <div className='flex-1 flex flex-col'>
                <div className='text-xs font-medium'>
                    Pick An Hour
                </div>
                <div className='mt-3  p-4 text-xs gap-2 flex flex-wrap overflow-y-auto border border-border rounded-1'>
                    {
                        props.data.map((item: any) => {
                            if (moment(item.start_time).format('YYYY-MM-DD') == props.date) {
                                return (
                                    <div key={item.id} className={`px-3 relative ${!item.is_active && 'opacity-30'} text-primary flex items-center h-[47px] flex-1 xl:min-w-[30%] lg:min-w-[45%] min-w-[100%] border-[2px] border-border rounded-[10px] ${timeSelected == item.id && 'bg-primary text-white'}`}>
                                        <Radio
                                            name='time'
                                            value={item.id}
                                            onChange={handleChangeTime}
                                        />
                                        <div className='ml-2 font-medium'>
                                            {
                                                moment(item.start_time).format('h:m A')
                                                +
                                                ' - '
                                                +
                                                moment(item.end_time).format('h:m A')
                                            }
                                        </div>
                                        {
                                            item.user_meeting ?
                                                <div className='bg-primary absolute bottom-2 right-2 w-1 h-1 rounded-full'></div>
                                                : 
                                                null
                                        }
                                    </div>
                                )
                            } else return null
                        })
                    }

                    <div className='px-3 flex items-center invisible h-0 flex-1 xl:min-w-[30%] lg:min-w-[45%] min-w-[100%] border-[2px] border-border rounded-[10px]'></div>
                    <div className='px-3 flex items-center invisible h-0 flex-1 xl:min-w-[30%] lg:min-w-[45%] min-w-[100%] border-[2px] border-border rounded-[10px]'></div>

                </div>
            </div>
        )
    else return null
}
