import { Route, Routes } from 'react-router-dom'
import Dashboard from './Dashboard'
import PatientsList from './patients/List'
import SessionArchive from './archive/SessionArchive'
import SessionSummery from './archive/SessionSummery'
import SessionUpcoming from './sessions/Upcoming'
import Session from './sessions/Session'
import MessagesList from './messages/List'
import Settings from './settings/Settings'
import ManageTiming from './manage-timing/ManageTiming'
import TherapistsList from './therapists/List'

export default function AppRoute() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/patients" element={<PatientsList />} />
      <Route path="/therapists" element={<TherapistsList />} />
      <Route path="/session-archive" element={<SessionArchive />} />
      <Route path="/session-archive/session" element={<SessionSummery />} />
      <Route path="/upcoming-sessions" element={<SessionUpcoming />} />
      <Route path="/upcoming-sessions/session" element={<Session />} />
      <Route path="/messages" element={<MessagesList />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/manage-timing" element={<ManageTiming />} />
    </Routes>
  )
}
