import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import LogoBlue from '../assets/images/logo_blue.svg'
import DashboardIcon from '../assets/images/dashboard.svg'
import ArrowRightIcon from '../assets/images/arrow_right.svg'
import UserIcon from '../assets/images/user.svg'
import PatientsIcon from '../assets/images/patients.svg'
import MessagesIcon from '../assets/images/messagesSide.svg'
import SettingIcon from '../assets/images/setting.svg'
import LogoutIcon from '../assets/images/logout.svg'
import { Bars3Icon } from '@heroicons/react/24/outline'
import NotificationIcon from '../assets/images/notification.svg'
import ModalNotification from '../components/ModalNotification'
import { useLogOutProfile } from '../hook/user'
import { toast } from 'react-toastify'
import { Auth } from '../enums/Enums'
import AccountingIcon from '../assets/images/accounting.svg'

export default function SideBar() {

  const navigate = useNavigate()
  const location = useLocation()
  const [active, setActive] = useState<string>('')
  const [open, setOpen] = useState(false)
  const [mobile, setMobile] = useState(false)
  const [locations, setLocations] = useState<string[]>([])
  const [showModalNotification, setShowModalNotification] = useState<boolean>(false)
  const logoutProfile = useLogOutProfile()

  const go = (path: string) => {
    navigate(path)
    setOpen(false)
  }

  useEffect(() => {

    const path = location.pathname.split('/')
    setActive(path[1])
    setLocations(path)

  }, [location])

  useEffect(() => {



    function updateSize() {
      if (window.innerWidth < 1024) {
        setMobile(true)
      } else {
        setMobile(false)
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);

  }, [])

  const logout = () => {
    logoutProfile.mutateAsync().then((_) => {
      localStorage.removeItem(Auth.token_name)
      navigate('/auth/login')
    }).catch((err) => {
      toast.error(err.response?.data?.message)
    })
  }

  return (
    <>
      <div className='fixed w-full z-20 flex items-center top-0 lg:pl-56 h-[72px] bg-white border-b border-border'>
        <div className='px-9 hidden flex-1 lg:flex justify-between text-sm'>
          <div className='flex'>
            {
              locations?.map((item: string, index) => {
                const splited = item.split('-')
                return (
                  <div key={item} className={`mr-3 flex ${locations.length > 0 && index === 0 ? 'text-black_opacity_20' : ''} items-center capitalize`}>
                    {
                      index > 0 &&
                      <div className='mr-3 text-black_opacity_20'>
                        /
                      </div>
                    }
                    {
                      item === '' ?
                        'Dashboard'
                        :
                        splited.length > 1 ?
                          splited.map((row) => {
                            return (
                              <span className='mr-1' key={row}>{row}</span>
                            )
                          })
                          :
                          item
                    }
                  </div>
                )
              })
            }
          </div>
          <img onClick={() => setShowModalNotification(true)} src={NotificationIcon} />
        </div>
        <div className='px-6 flex justify-between w-full lg:hidden'>
          <Bars3Icon onClick={() => setOpen(!open)} className='w-7' />
          <img onClick={() => setShowModalNotification(true)} src={NotificationIcon} />
        </div>
      </div>

      <div onClick={() => setOpen(false)} className={`${!open || !mobile ? 'hidden invisible' : ''} visible transition-all bg-black_opacity_40 fixed w-full h-full z-20`}>
      </div>
      <div className={`w-[240px] ${!open && mobile ? `hidden invisible` : ''} transition-all visible duration-1000 lg:block max-h-full overflow-y-auto min-h-full text-sm fixed z-30 bg-white border-r border-border px-5 pt-5`}>
        <div className='flex mt-2'>
          <img src={LogoBlue} alt='' />
          <div className='text-primary font-medium ml-2'>
            Autism Platform
          </div>
        </div>

        <div onClick={() => go('/')} className={`mt-8 flex cursor-pointer items-center ${active === '' && 'text-primary'}`}>
          <img className={active === '' ? `active_side` : ''} src={DashboardIcon} alt='' />
          <div className='ml-3'>
            Dashboard
          </div>
        </div>

        <div>
          <div className='mt-7 opacity-40'>
            Reception
          </div>

          <div onClick={() => go('/patients')} className={`mt-2 flex py-2 px-2 rounded-lg w-full items-center cursor-pointer ${active === 'patients' && 'bg-[#F0F4FC] text-primary'}`}>
            <img src={ArrowRightIcon} alt='' />
            <img className={`ml-2 ${active === 'patients' && 'active_side'}`} src={UserIcon} alt='' />
            <div className='ml-2'>
              Patients
            </div>
          </div>

          <div onClick={() => go('/therapists')} className={`mt-2 flex py-2 px-2 rounded-lg w-full items-center cursor-pointer ${active === 'therapists' && 'bg-[#F0F4FC] text-primary'}`}>
            <img src={ArrowRightIcon} alt='' />
            <img className={`ml-2 ${active === 'therapists' && 'active_side'}`} src={PatientsIcon} alt='' />
            <div className='ml-2'>
              Therapists
            </div>
          </div>

          <div className='mt-7 opacity-40'>
            Account
          </div>


          <div onClick={() => go('/messages')} className={`mt-2 flex py-2 px-2 rounded-lg w-full items-center cursor-pointer ${active === 'messages' && 'bg-[#F0F4FC] text-primary'}`}>
            <img src={ArrowRightIcon} alt='' />
            <img className={`ml-2 ${active === 'messages' && 'active_side'}`} src={MessagesIcon} alt='' />
            <div className='ml-2'>
              Messages
            </div>
          </div>

          <div onClick={() => go('/settings')} className={`mt-2 flex py-2 px-2 rounded-lg w-full items-center cursor-pointer ${active === 'settings' && 'bg-[#F0F4FC] text-primary'}`}>
            <img src={ArrowRightIcon} alt='' />
            <img className={`ml-2 ${active === 'settings' && 'active_side'}`} src={SettingIcon} alt='' />
            <div className='ml-2'>
              Settings
            </div>
          </div>

          <div onClick={() => go('/manage-timing')} className={`mt-2 flex py-2 px-2 rounded-lg w-full items-center cursor-pointer ${active === 'manage-timing' && 'bg-[#F0F4FC] text-primary'}`}>
            <img src={ArrowRightIcon} alt='' />
            <img className={`ml-2 ${active === 'manage-timing' && 'active_side'}`} src={AccountingIcon} alt='' />
            <div className='ml-2'>
            Manage Timing
            </div>
          </div>

        </div>

        <div onClick={logout} className='mt-8 flex items-center cursor-pointer'>
          <img src={LogoutIcon} alt='' />
          <div className='ml-3'>
            Log Out
          </div>
        </div>



      </div>

      <ModalNotification
        open={showModalNotification}
        close={() => setShowModalNotification(false)}
      />
    </>
  )
}
