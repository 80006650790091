import SearchIcon from '../../assets/images/search.svg'
import Select from '../../components/Select'
import Input from '../../components/Input'
import DoctorImage from '../../assets/images/doctor.webp'
import CalenderIcon from '../../assets/images/calender_blue.svg'
import { useGetTherapiistsList } from '../../hook/therapist'
import { useEffect, useState } from 'react'
import Pagination from '../../components/Pagination'
import ModalCalenderTherapist from '../../components/therapist/ModalCalenderTherapist'
import PageLoading from '../../components/PageLoading'

export default function List() {

    const [offset, setOffset] = useState<number>(0)
    const [search, setSearch] = useState('')
    const [search2, setSearch2] = useState<string>('')
    const [page, setPage] = useState(1)
    const getTherapist = useGetTherapiistsList(offset, search)
    const [showModalCalender, setShowModalCalender] = useState(false)
    const [therapistId, setTherapistId] = useState('')

    const handleChangePage = (pageNumber: number) => {
        var p = pageNumber - 1
        setPage(pageNumber)
        setOffset(p * 20)
    }

    useEffect(() => {
        const timeout = setTimeout(() => {

            setPage(1)
            setOffset(0)
            setSearch(search2)

        }, 1500)

        return () => clearTimeout(timeout)


    }, [search2])

    const handleShowModalCalender = (therapist_id: string) => {
        setTherapistId(therapist_id)
        setShowModalCalender(true)
    }

    return (
        <div className='py-4'>
            <div className='px-6'>
                <div className='text-center md:text-left'>
                    <span className='font-medium text-sm'>
                        Assessment Therapists
                    </span>
                    <span className='text-xs ml-2 text-black_opacity_40'>
                        {getTherapist.data?.count} Therapists
                    </span>
                </div>
                <div className='mt-3 md:flex-row flex-col items-center flex gap-2 w-full'>
                    <div className='relative mt-2 md:mt-0 w-52'>
                        <Input value={search2} onChange={(e) => setSearch2(e.target.value)} classNameInput='text-xs px-3' placeholder='Search Names...' />
                        <img src={SearchIcon} className='absolute right-3 top-0 bottom-0 my-auto' />
                    </div>
                    {/* <div>
                        <Select
                            items={[
                                {
                                    id: 1,
                                    text: 'Sort by less Week Sessions'
                                },
                            ]}
                            onChange={() => null}
                            placeholder='Sort By'
                        />
                    </div> */}
                </div>
            </div>

            {
                getTherapist.isLoading ?
                    <PageLoading />
                    :
                    <div className="relative overflow-x-auto mt-2">
                        <table className="w-full text-xs ">
                            <thead className='thead'>
                                <tr>
                                    <td scope="col" className='td'>
                                        Therapists
                                    </td>
                                    <td scope="col" className='td'>
                                        Sessions In The Last 30 Days
                                    </td>
                                    <td scope="col" className='td'>
                                        Sessions In The Last 7 Days
                                    </td>
                                    <td scope="col" className='td'>
                                        action
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    getTherapist.data?.results?.map((item: any) => {
                                        return (
                                            <tr key={item.id} className="border-b ">
                                                <td scope="row" className="td">
                                                    <div className='flex items-center'>
                                                        <img src={item.avatar} className='w-6 h-6 object-cover rounded-full' />
                                                        <div className='ml-2'>
                                                            {item.first_name + ' ' + item.last_name}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td scope="row" className="td">
                                                    {item.meetings_last_month}
                                                </td>
                                                <td className="td">
                                                    {item.meetings_last_week}
                                                </td>
                                                <td className="td text-primary">
                                                    <div className='flex items-center'>
                                                        <img src={CalenderIcon} />
                                                        <div onClick={() => handleShowModalCalender(item.id)} className='ml-2 cursor-pointer'>
                                                            View Calendar
                                                        </div>
                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                        {
                            getTherapist.data?.count ?
                                <Pagination
                                    currentPage={page}
                                    onChange={(pageNumber) => handleChangePage(pageNumber)}
                                    pageSize={20}
                                    total={getTherapist.data?.count}
                                />
                                : null
                        }

                        <ModalCalenderTherapist
                            close={() => setShowModalCalender(false)}
                            open={showModalCalender}
                            therapist_id={therapistId}
                        />
                    </div>
            }

        </div>
    )
}
