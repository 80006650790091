import { useCallback, useState } from 'react'
import DoctorImage from '../../assets/images/doctor.webp'
import EditIcon from '../../assets/images/edit.svg'
import Input from '../Input'
import { useFormik } from 'formik'
import { useDropzone } from 'react-dropzone'
import Button from '../Button'

interface Props {
    changeStep: (value:number) => void,
}

export default function Information(props:Props) {

    const [fileSrc, setFileSrc] = useState<any>('')

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            country: '',
        },
        onSubmit: (values) => {
            props.changeStep(2)
        }
    })

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const reader = new FileReader()

        reader.readAsDataURL(acceptedFiles[0]);
        reader.onloadend = function () {
            setFileSrc(reader.result)
        }
    }, [])
    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    return (
        <div>
            <div className='text-xs'>
                Upload Picture
            </div>
            <div {...getRootProps()} className='relative w-16 h-16 mt-2 rounded-[8px] overflow-hidden'>
                <input {...getInputProps()} />
                <img src={!fileSrc ? DoctorImage : fileSrc} className='w-full h-full object-cover' />
                {
                    !fileSrc &&
                    <div className='absolute bg-black_opacity_40 w-full h-full top-0 flex justify-center items-center'>
                        <img src={EditIcon} />
                    </div>
                }
            </div>

            <div>
                <Input
                    label='First Name'
                    value={formik.values.first_name}
                    name='first_name'
                    onChange={formik.handleChange}
                />
                <Input
                    label='Last name'
                    value={formik.values.last_name}
                    name='last_name'
                    onChange={formik.handleChange}
                />
                <Input
                    label='Phone number'
                    value={formik.values.phone}
                    name='phone'
                    onChange={formik.handleChange}
                />
                <Input
                    label='Email'
                    value={formik.values.email}
                    name='email'
                    onChange={formik.handleChange}
                />
                <Input
                    label='Country'
                    value={formik.values.country}
                    name='country'
                    onChange={formik.handleChange}
                />

                <div className='mt-8'>
                    <Button
                        title='Next Step'
                        onClick={formik.handleSubmit}
                    />
                </div>
            </div>
        </div>
    )
}
