import { Fragment, useEffect, useState } from 'react'
import { useGetTherapistDetail, useGetTherapistTime } from '../../hook/therapist'
import Button from '../Button'
import PageLoading from '../PageLoading'
import { Calendar } from 'react-multi-date-picker'
import moment from 'moment'
import CalenderIcon from '../../assets/images/calender_blue.svg'

interface Props {
    open: boolean,
    close: () => void,
    therapist_id: string,
}

export default function ModalCalenderTherapist(props: Props) {

    const [time, setTime] = useState<any>()
    const getTherapistTime = useGetTherapistTime(props.therapist_id, time?.month, time?.year)
    const therapist = useGetTherapistDetail(props.therapist_id)
    const [datesHaveMeeting, setDatesHaveMeeting] = useState<string[]>([])
    const [dateSelected, setDateSelected] = useState<string>()

    useEffect(() => {

        const date = new Date()
        setTime({
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        })

    }, [])

    useEffect(() => {

        const array: string[] = []
        getTherapistTime.data?.map((item: any) => {
            if (item.user_meeting) {
                const date = moment(item.start_time).format('YYYY-MM-DD')
                const index = array.indexOf(date)
                if (index === -1) {
                    array.push(date)
                }
            }
        })
        setDatesHaveMeeting(array)


    }, [getTherapistTime.data])

    const handleChangeDate = (dateObject: any) => {
        var date = dateObject.year + '-' + dateObject.month.number + '-' + dateObject.day
        setDateSelected(moment(date).format('YYYY-MM-DD'))
    }

    const handleChangeMonthYear = (d:any) => {        
        setTime({
            month: d.month.number,
            year: d.year,
        })
    }

    return (
        <>
            <div onClick={props.close} className={`${!props.open ? 'hidden invisible' : ''} visible transition-all bg-black_opacity_40 top-0 left-0 fixed w-full h-full z-30`}>
            </div>
            <div className={`fixed top-0 overflow-y-auto right-0 z-40 w-[80%] md:w-[480px] px-5 py-5 h-full transition-all duration-500 transform bg-white ${props.open ? 'translate-x-0' : 'translate-x-full'}`}>

                <div className='flex justify-between items-center border-b border-border pb-6'>
                    <div className='text-base font-medium'>
                        Therapist
                    </div>
                    <div className='w-[72px]'>
                        <Button
                            title='Done'
                            onClick={props.close}
                        />
                    </div>
                </div>

                {
                    therapist.isInitialLoading ?
                        <PageLoading />
                        :
                        <Fragment>
                            <div className='mt-2 flex gap-2 items-center'>
                                <img src={therapist.data?.avatar} className='w-12 h-12 rounded-full' />
                                <div>
                                    <div className='font-medium'>
                                        {therapist.data?.__str__}
                                    </div>
                                    <div className='text-sm mt-1'>
                                        email@gmail.com
                                    </div>
                                </div>
                            </div>

                            <div className='mt-3 text-sm'>
                                {therapist.data?.meetings_last_week} Sessions in this week
                            </div>

                            <div className='mt-2 text-sm border-b border-border pb-4'>
                                {therapist.data?.meetings_last_month} Session in this month
                            </div>

                            <div className='mt-4 font-medium'>
                                Calender
                            </div>

                            <div className='mt-3'>
                                <Calendar
                                    onChange={handleChangeDate}
                                    mapDays={({ date }) => {
                                        let color
                                        var d = moment(date.year + '-' + date.month + '-' + date.day).format('YYYY-MM-DD')
                                        const obj = datesHaveMeeting.find(o => o === d)
                                        if (obj) color = "red"
                                        if (color) return { className: "highlight highlight-" + color }
                                    }}
                                    onMonthChange={handleChangeMonthYear}
                                    onYearChange={handleChangeMonthYear}
                                />
                            </div>

                            {
                                dateSelected &&
                                <div>
                                    <div className='mt-4 h-12 text-primary font-medium text-sm bg-secondary rounded-sm flex justify-center items-center'>
                                        {moment(dateSelected).format('ll')}
                                    </div>

                                    <div className='mt-4 text-sm'>
                                        Reserved
                                    </div>

                                    {
                                        getTherapistTime.data?.map((item: any) => {
                                            if (moment(item.start_time).format('YYYY-MM-DD') == dateSelected && item.user_meeting) {
                                                return (
                                                    <div className='mt-3 flex gap-2 text-xs items-center text-primary'>
                                                        <img src={CalenderIcon} />
                                                        <div>
                                                            {
                                                                moment(item?.start_time).format('h:m A')
                                                                +
                                                                ' - '
                                                                +
                                                                moment(item?.end_time).format('h:m A')
                                                                +
                                                                ' - '
                                                                +
                                                                item?.user_meeting?.parent?.__str__
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            } else return null
                                        })
                                    }

                                    <div className='mt-4 text-sm mb-2'>
                                        Free
                                    </div>

                                    <div className='flex gap-3 flex-wrap'>
                                    {
                                        getTherapistTime.data?.map((item: any) => {
                                            if (moment(item.start_time).format('YYYY-MM-DD') == dateSelected && !item.user_meeting) {
                                                return (
                                                    <div className='mt-1 flex gap-2 text-xs items-center text-primary'>
                                                        <img src={CalenderIcon} />
                                                        <div>
                                                            {
                                                                moment(item?.start_time).format('h:m A')
                                                                +
                                                                ' - '
                                                                +
                                                                moment(item?.end_time).format('h:m A')
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            } else return null
                                        })
                                    }
                                    </div>
                                </div>
                            }
                        </Fragment>
                }



            </div>
        </>
    )
}
