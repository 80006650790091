import { useMutation } from "@tanstack/react-query"
import { LoginCheckCodeInterface, LoginCheckPasswordInterface } from "../../interface/Auth"
import * as api from '../../api/auth'

export const useLoginCheckPassword = () => {
    return useMutation((variables: LoginCheckPasswordInterface) => api.LoginCheckPassword(variables))
}

export const useLoginCheckCode = () => {
    return useMutation((variables: LoginCheckCodeInterface) => api.LoginCheckCode(variables))
}