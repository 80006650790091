import React, { useEffect, useState } from 'react'
import Switch from '../Switch'
import moment from 'moment'
import { useChangeHourStatus } from '../../hook/timing'
import { ChaneHourStatusInterface } from '../../interface/Meeting'
import { toast } from 'react-toastify'

interface Props {
    date: any,
    data: any,
    timeId: any,
    refetch: () => void,
}

export default function DetailOfHour(props: Props) {

    const [session, setSession] = useState<any>()
    const [active, setActive] = useState(false)
    const changeHourStatus = useChangeHourStatus(props.timeId)

    useEffect(() => {

        if (props.data && props.timeId) {
            props.data?.map((item: any) => {
                if (item.id == props.timeId) {
                    setSession(item)
                    setActive(item.is_active)
                }
            })
        }

    }, [props.data && props.timeId])

    const handleDayChangeStatus = (value: boolean) => {
        const params: ChaneHourStatusInterface = {
            is_active: value,
        }

        changeHourStatus.mutateAsync(params).then((_) => {
            setActive(value)
            props.refetch()
        }).catch((err) => {
            toast.error(err.response?.data?.message)
        })
    }

    if (props.data && props.timeId && session)
        return (
            <div className='flex-1'>
                <div className='text-xs'>
                    Status Of The Chosen Hour
                </div>
                <div className='mt-3 px-3 border rounded-1 border-border'>

                    <div className='sm:h-14 sm:py-0 py-3 sm:flex-row flex-col border-b border-border flex sm:items-center justify-between'>
                        <div className='font-medium text-primary text-xs'>
                            {
                                moment(session?.start_time).format('h:m A')
                                +
                                ' Till '
                                +
                                moment(session?.end_time).format('h:m A')
                            }
                        </div>
                        {
                            moment().format('YYYY-MM-DD') <= props.date ?
                                <div className='flex justify-between sm:justify-start items-center sm:mt-0 mt-3'>
                                    <div className='text-xs'>
                                        Is This Hour a Working Hour?
                                    </div>
                                    <div className='ml-2'>
                                        <Switch
                                            active={active}
                                            onChange={handleDayChangeStatus}
                                            isLoading={changeHourStatus.isLoading}
                                        />
                                    </div>
                                </div>
                                : null
                        }

                    </div>
                    <div className='mt-3 pb-4'>
                        {
                            session.user_meeting ?
                                <div className='h-8 w-fit bg-secondary items-center rounded-1 flex px-2'>
                                    <img src={session?.user_meeting?.parent?.avatar} className='w-6 h-6 rounded-full border border-primary' />
                                    <div className='ml-2 text-xs font-medium text-primary'>
                                        {session?.user_meeting?.parent?.__str__}
                                    </div>
                                </div>
                                :
                                <div className='text-xs'>
                                    You Have No Sessions In This Hour
                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    else return null
}
