import { useDropzone } from 'react-dropzone'
import UploadIcon from '../assets/images/upload.svg'
import { useCallback, useState } from 'react'
import ReactLoading from 'react-loading'

interface Props {
    label?: string,
    readonly?: boolean,
    placeholder: string,
    onChange?: (file: File) => void,
    isLoading?: boolean,
}

export default function UploadBox(props: Props) {

    const [file, setFile] = useState<File>()

    const onDrop = useCallback((acceptedFiles: File[]) => {

        if (props.onChange) {
            props.onChange(acceptedFiles[0])
        }

        if (!props.readonly) {
            setFile(acceptedFiles[0])
        }

    }, [])
    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    return (
        <div className='w-full'>
            {
                props.label &&
                <div className="mt-4 text-xs">
                    {props.label}
                </div>
            }
            <div {...getRootProps()} className={`h-12 ${props.label && 'mt-2'} px-4 border border-border rounded-md flex justify-between items-center`}>
                <input {...getInputProps()} />
                <div className='text-sm font-medium text-primary'>
                    {
                        file ?
                            file.name
                            :
                            props.placeholder
                    }
                </div>
                {
                    props.isLoading ?
                    <ReactLoading type='spin' color='#4C4DDC' width={18} height={18} />
                    :
                <img src={UploadIcon} />
                }
            </div>
        </div>
    )
}
