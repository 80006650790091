import { useMutation, useQuery } from '@tanstack/react-query'
import * as api from '../../api/timing'
import { STALETIME } from '../../config/const'
import { CancelMetingListInterface, ChaneHourStatusInterface, ChangeDayStatusInterface } from '../../interface/Meeting'

export const useGetTiming = (month: string, year: string) => {
    return useQuery(['timing', month, year], () => api.GetTiming(month, year), {
        staleTime: STALETIME,
        retry: false,
        enabled: !!month && !!year,
    })
}

export const useCancelMeetingList = () => {
    return useMutation((variables: CancelMetingListInterface) => api.CancelMeetingList(variables))
}

export const useChangeDayStatus = () => {
    return useMutation((variables: ChangeDayStatusInterface) => api.ChangeDayStatus(variables))
}

export const useChangeHourStatus = (time_id: string) => {
    return useMutation((variables: ChaneHourStatusInterface) => api.ChangeHourStatus(time_id, variables))
}