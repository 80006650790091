import { XMarkIcon } from '@heroicons/react/24/outline'
import { useGetInfoConversation } from "../../hook/messages"
import PageLoading from "../PageLoading"
import ConversationComponent from "./ConversationComponent"

interface Props {
    open: boolean,
    close: () => void,
    conversation_id: any,
}

export default function ModalConversation(props: Props) {

    
    const getInfoConversation = useGetInfoConversation(props.conversation_id)

    return (
        <>
            <div onClick={props.close} className={`${!props.open ? 'hidden invisible' : ''} visible transition-all bg-black_opacity_40 fixed w-full h-full top-0 left-0 z-30`}>
            </div>
            <div className={`fixed top-0 overflow-y-auto right-0 z-40 sm:w-[482px] w-full px-5 py-5 h-full transition-all duration-500 transform flex flex-col bg-white ${props.open ? 'translate-x-0' : 'translate-x-full'}`}>

                <div>
                    <XMarkIcon onClick={props.close} className="w-6 mb-2 sm:hidden" />
                </div>

                {
                    getInfoConversation.isLoading ?
                        <PageLoading />
                        :
                        <ConversationComponent conversation_id={props.conversation_id} />
                }
            </div>
        </>
    )
}
