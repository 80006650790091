import CalenderIcon from '../assets/images/calender.svg'
import CameraIcon from '../assets/images/camera.svg'
import SearchIcon from '../assets/images/search_folder.svg'
import { Fragment, useState } from 'react'
import { useGetNewPatients, useGetProfileInfo } from '../hook/user'
import PageLoading from '../components/PageLoading'
import { useGetConversations } from '../hook/messages'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import ModalParentInformation from '../components/patients/ModalParentInformation'
import ModalConversation from '../components/messages/ModalConversation'
import Pagination from '../components/Pagination'

export default function Dashboard() {

  const navigate = useNavigate()
  const [parentId, setParentId] = useState('')
  const [showModalInformation, setShowModalInformation] = useState(false)
  const [conversationId, setConversationId] = useState<any>()
  const [showModalConversation, setShowModalConversation] = useState<boolean>(false)
  const [offset, setOffset] = useState<number>(0)
  const [page, setPage] = useState(1)
  const getProfile = useGetProfileInfo()
  const getNewParents = useGetNewPatients(offset)
  const getConversations = useGetConversations(0, '', '&limit=5')
  

  const handleChangePage = (pageNumber: number) => {
    var p = pageNumber - 1
    setPage(pageNumber)
    setOffset(p * 20)
}

  const handleShowInformation = (slug: string) => {
    setParentId(slug)
    setShowModalInformation(true)
  }

  const handleShowConversation = (coonversationId: string) => {
    setConversationId(coonversationId)
    setShowModalConversation(true)
  }


  return (
    <div className='py-4 text-sm'>

      {
        getProfile.isLoading || getNewParents.isLoading || getConversations.isLoading ?
          <PageLoading />
          :
          <Fragment>
            <div className='px-6 pb-4 border-b border-border'>
              <div className='text-center sm:text-left'>
                Welcome,
              </div>

              <div className='flex flex-col sm:flex-row justify-between mt-4 items-center'>
                <div className='flex items-center'>
                  <img src={getProfile.data?.avatar} alt='' className='w-12 h-12 rounded-full object-cover border border-black' />
                  <div className='text-primary text-base font-medium ml-3'>
                    {getProfile.data?.first_name + ' ' + getProfile.data?.last_name}
                  </div>
                </div>

                {/* <div className='bg-secondary w-[199px] mt-6 sm:mt-0 h-12 rounded-[4px] flex justify-center items-center'>
            <img src={MessageIcon} alt='' />
            <div className='text-primary font-medium ml-2'>
              3 Unread Messages
            </div>
          </div> */}
              </div>
            </div>

            <div className='px-6 mt-4'>
              <div className='flex flex-wrap lg:flex-nowrap justify-between gap-4'>

                <div className='flex-1 lg:min-w-[20%] min-w-[100%] md:min-w-[40%] h-28 rounded-lg flex flex-col justify-center px-6 border border-border bg-yellow'>
                  <div className='font-semibold'>
                    New Patients
                  </div>
                  <div className='mt-4 text-2xl font-medium'>
                    {getNewParents.data?.count}
                  </div>
                </div>

                <div className='flex-1 lg:min-w-[20%] min-w-[100%] md:min-w-[40%] h-28 bg-secondary text-primary rounded-lg flex flex-col justify-center px-6'>
                  <div className='font-semibold'>
                    Total Patients
                  </div>
                  <div className='mt-4 text-2xl font-medium'>
                    17
                  </div>
                </div>

                <div className='flex-1 lg:min-w-[20%] min-w-[100%] md:min-w-[40%] h-28 bg-primary text-white rounded-lg flex flex-col justify-center px-6'>
                  <div className='font-semibold'>
                    Total Therapists
                  </div>
                  <div className='mt-4 text-2xl font-medium'>
                    210
                  </div>
                </div>

              </div>

              <div className='mt-6 justify-between border-b border-border pb-6'>
                <div className='font-medium'>
                  New Patients
                </div>
                <div className="relative overflow-x-auto mt-3">
                  <table className="w-full text-xs ">
                    <thead className='thead'>
                      <tr>
                        <td scope="col" className='td'>
                          Caretaker
                        </td>
                        <td scope="col" className='td'>
                          Patient
                        </td>
                        <td scope="col" className='td'>
                          Age
                        </td>
                        <td scope="col" className='td'>
                          Phone Number
                        </td>
                        <td scope="col" className='td'>
                          Reservation Date
                        </td>
                        <td scope="col" className='td'>
                          Session Type
                        </td>
                        <td scope="col" className='td'>
                          Status
                        </td>
                        <td scope="col" className='td'>

                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        getNewParents.data?.results?.map((item: any) => {
                          return (
                            <tr key={item.id} className="border-b ">
                              <td scope="row" className="td">
                                <div className='flex items-center'>
                                  <img src={item?.parent?.avatar}
                                    className='w-6 h-6 object-cover rounded-full' />
                                  <div className='ml-2'>
                                    {item.parent?.__str__}
                                  </div>
                                </div>
                              </td>
                              <td scope="row" className="td">
                                <div className='flex items-center'>
                                  <img src={item?.learner?.avatar}
                                    className='w-6 h-6 object-cover rounded-full' />
                                  <div className='ml-2'>
                                    {item.learner?.__str__}
                                  </div>
                                </div>
                              </td>
                              <td className="td">
                                {item.learner?.age} Years Old
                              </td>

                              <td className="td">
                                {item.parent?.phone_number}
                              </td>
                              <td className="td">
                                {
                                  item.next_meeting ?
                                    <div className='flex items-center'>
                                      <img src={CalenderIcon} />
                                      <div className='ml-2'>
                                        {moment(item.next_meeting?.start_time).format('lll')}
                                      </div>
                                    </div> : null
                                }

                              </td>
                              <td className="td capitalize">
                                {item.next_meeting?.meeting_type}
                              </td>
                              <td className='td'>
                                <div style={{
                                  background: item.parent?.parent_status?.background_color,
                                  color: item.parent?.parent_status?.font_color
                                }} className={`ml-2 font-medium p-2 py-1 w-fit rounded-1`}>
                                  {item.parent?.parent_status?.text}
                                </div>
                              </td>
                              <td className='td text-primary'>
                                <div className='flex gap-6'>
                                  <img
                                    onClick={() => navigate(`/upcoming-sessions/session?hideborder=1&parent-id=${item.parent?.id}`)}
                                    src={CameraIcon} />
                                  <img onClick={() => handleShowInformation(item.parent?.id)}
                                    src={SearchIcon} />
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      }

                    </tbody>
                  </table>
                </div>

                {
                    getNewParents.data?.count ?
                        <Pagination
                            currentPage={page}
                            onChange={(pageNumber) => handleChangePage(pageNumber)}
                            pageSize={20}
                            total={getNewParents.data?.count}
                        />
                        : null
                }
              </div>



              <div className='mt-6 flex justify-between items-center'>
                <div className='font-medium'>
                  Conversations
                </div>
              </div>


              <div className="relative overflow-x-auto mt-3">
                <table className="w-full text-xs ">
                  <thead className='thead'>
                    <tr>
                      <td scope="col" className='td'>
                        Conversation
                      </td>
                      <td scope="col" className='td'>
                        last Message
                      </td>
                      <td scope="col" className='td'>
                        Last Message’s Date
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      getConversations.data?.results?.map((item: any) => {
                        return (
                          <tr key={item.id} onClick={() => handleShowConversation(item.id)} className={`border-b ${item?.last_message?.is_seen ? '' : 'bg-fifth'}`}>
                            <td scope="row" className="td">
                              <div className='flex items-center'>
                                <img src={item.contact.avatar} className='w-6 h-6 rounded-full object-cover' />
                                <div className='ml-2'>{item.contact.first_name + ' ' + item.contact.last_name}</div>
                              </div>
                            </td>
                            <td scope="row" className="td font-medium">
                              {item.last_message?.message}
                            </td>
                            <td scope="row" className="td">
                              {
                                item.last_message?.insert_dt &&
                                <div className='flex'>
                                  <img src={CalenderIcon} />
                                  <div className='ml-2'>
                                    {moment(item.last_message.insert_dt).format('lll')}
                                  </div>
                                </div>
                              }

                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>


            </div>

          </Fragment>
      }
      <ModalParentInformation
        open={showModalInformation}
        close={() => setShowModalInformation(false)}
        parentId={parentId}
      />

      <ModalConversation
        open={showModalConversation}
        close={() => setShowModalConversation(false)}
        conversation_id={conversationId}
      />

    </div>
  )
}
