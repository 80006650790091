import { ChangePasswordInterface } from '../../interface/Profile'
import axios from '../../config/axios'

export const GetProfileInfo = async () => {
    const { data } = await axios.get('/v1/user/profile/info/')
    return data
}

export const LogoutProfile = async () => {
    const { data } = await axios.post('/v1/user/profile/logout/')
    return data
}

export const ChangePassword = async (params:ChangePasswordInterface) => {
    const { data } = await axios.post('/v1/user/profile/change_password/', params)
    return data
}

export const UpdateProfile = async (params:any) => {
    const { data } = await axios.patch('/v1/user/profile/info/', params)
    return data
}

export const GetDocuments = async () => {
    const { data } = await axios.get('/v1/user/documents/')
    return data
}

export const UploadDocuments = async (params:any) => {
    const { data } = await axios.post('/v1/user/documents/', params)
    return data
}

export const DeleteDocuments = async (id:string) => {
    const { data } = await axios.delete(`/v1/user/documents/${id}/`)
    return data
}

export const GetNotifications = async () => {
    const { data } = await axios.get(`/v1/user/notifications/`)
    return data
}

export const WipeNotifications = async (params:any) => {
    const { data } = await axios.post(`/v1/user/notifications/wipe_list/`, params)
    return data
}

export const GetNewPatients = async (offset?:number) => {
    var query = `?offset=${offset ?  offset : 0}`
    const { data } = await axios.get(`/v1/receptionist/parents/new_list/${query}`)
    return data
}