import { useState } from 'react'
import Toggle from '../../components/Toggle'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'

export default function SessionSummery() {

    const [toggleTaskForm, setToggleTaskForm] = useState<number>(1)
    const navigate = useNavigate()

    return (
        <div className='bg-fourth flex-1'>
            <div className='bg-white py-4 px-6 rounded-2 border border-border'>
                <div className='text-title font-medium pb-5 border-b border-border'>
                    Your Assesment
                </div>
                <div className='flex flex-wrap md:flex-row flex-col'>
                    <div className='flex pr-4 md:border-r border-border mt-4 md:mr-4 '>
                        <div className='font-medium capitalize'>
                            tactile:
                        </div>
                        <div className='capitalize text-primary ml-2 font-medium'>
                            better
                        </div>
                    </div>
                    <div className='flex pr-4 md:border-r border-border mt-4 md:mr-4 '>
                        <div className='font-medium capitalize'>
                            cognitive::
                        </div>
                        <div className='capitalize text-primary ml-2 font-medium'>
                            not changed
                        </div>
                    </div>
                    <div className='flex pr-4 md:border-r border-border mt-4 md:mr-4 '>
                        <div className='font-medium capitalize'>
                            movement:
                        </div>
                        <div className='capitalize text-primary ml-2 font-medium'>
                            worse
                        </div>
                    </div>
                    <div className='flex mt-4 '>
                        <div className='font-medium capitalize'>
                            perception & movement:
                        </div>
                        <div className='capitalize text-primary ml-2 font-medium'>
                        worse
                        </div>
                    </div>
                </div>

                <div className='mt-5 text-sm w-full h-[75px] rounded-2 bg-[#f9f9f9] border border-[#eeeeee] py-3 px-4'>
                    she is so talented
                </div>
            </div>

            <div className='mt-3 bg-white border border-border py-4 px-6'>
                <div className='pb-4 border-b border-border'>
                    <div className='w-[160px]'>
                        <Toggle
                            items={[
                                {
                                    id: 1,
                                    text: 'task'
                                },
                                {
                                    id: 2,
                                    text: 'form'
                                },
                            ]}
                            onChange={(value) => setToggleTaskForm(value)}
                            toggle={toggleTaskForm}
                        />
                    </div>
                </div>

                {
                    toggleTaskForm === 1 ?
                        <div className='mt-3'>
                            <div className='font-medium text-title'>
                                Patient Tasks
                            </div>

                            <div className="relative overflow-x-auto mt-3">
                                <table className="w-full text-xs ">
                                    <thead className='thead'>
                                        <tr>
                                            <td scope="col" className='td'>
                                                Type
                                            </td>
                                            <td scope="col" className='td'>
                                                Title
                                            </td>
                                            <td scope="col" className='td'>
                                                Description
                                            </td>
                                            <td scope="col" className='td'>
                                                Status
                                            </td>
                                            <td scope="col" className='td'>
                                                Action
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="border-b">
                                            <td scope="row" className="td">
                                                cognitive
                                            </td>
                                            <td scope="row" className="td">
                                                playing with different objects
                                            </td>
                                            <td scope="row" className="td">
                                                it was too easy.
                                            </td>
                                            <td scope="row" className="td text-green">
                                                Done
                                            </td>
                                            <td scope="row" className="td">
                                                <div className='w-20'>
                                                    <Button
                                                        title='View'
                                                        height={29}
                                                        className='bg-secondary'
                                                        textColor='#273D8B'
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='mt-6'>
                                <div className='font-medium text-title pb-6 border-b border-border'>
                                    Newly Assigned Tasks
                                </div>

                                <div className="relative overflow-x-auto mt-3">
                                    <table className="w-full text-xs ">
                                        <thead className='thead'>
                                            <tr>
                                                <td scope="col" className='td'>
                                                    Type
                                                </td>
                                                <td scope="col" className='td'>
                                                    Title
                                                </td>
                                                <td scope="col" className='td'>
                                                    Status
                                                </td>
                                                <td scope="col" className='td'>
                                                    Action
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-b">
                                                <td scope="row" className="td">
                                                    cognitive
                                                </td>
                                                <td scope="row" className="td">
                                                    playing with different objects
                                                </td>
                                                <td scope="row" className="td">
                                                    Undone
                                                </td>
                                                <td scope="row" className="td">
                                                    <div className='flex'>
                                                    <div className='w-20'>
                                                        <Button
                                                            title='View'
                                                            height={29}
                                                            className='bg-secondary'
                                                            textColor='#273D8B'
                                                        />
                                                    </div>
                                                    <div className='w-20 ml-2'>
                                                        <Button
                                                            title='Delete'
                                                            height={29}
                                                            bg='#FFE8E8'
                                                            textColor='#AC1A1A'
                                                        />
                                                    </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='mt-3'>
                            <div className="relative overflow-x-auto mt-3">
                                <table className="w-full text-xs ">
                                    <thead className='thead'>
                                        <tr>
                                            <td scope="col" className='td'>
                                                Name
                                            </td>
                                            <td scope="col" className='td'>
                                                Status
                                            </td>
                                            <td scope="col" className='td'>
                                                Action
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="row" className="td">
                                                MChat
                                            </td>
                                            <td scope="row" className="td text-green">
                                                Done
                                            </td>
                                            <td scope="row" className="td flex">
                                                <div className='w-20'>
                                                    <Button
                                                        title='Resulst'
                                                        height={29}
                                                        className='bg-secondary'
                                                        textColor='#273D8B'
                                                    />
                                                </div>
                                                <div className='w-20 ml-3'>
                                                    <Button
                                                        title='Redo'
                                                        height={29}
                                                        className='bg-secondary'
                                                        textColor='#273D8B'
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="td">
                                                Gars
                                            </td>
                                            <td scope="row" className="td text-[#B5B5B5]">
                                                Undone
                                            </td>
                                            <td scope="row" className="td flex">
                                                <div className='w-20'>
                                                    <Button
                                                        title='Redo'
                                                        height={29}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                }
            </div>

            <div className='mt-6 max-w-[194px]'>
                <Button
                title='Back To Archives'
                onClick={() => navigate(-1)}
                />
            </div>
        </div>
    )
}
