import { useState, Fragment } from 'react'
import { Switch } from '@headlessui/react'
import ReactLoading from 'react-loading'

interface Props {
    active: boolean,
    onChange: (value: boolean) => void,
    isLoading?: boolean,
}

export default function SwitchComponent(props: Props) {

    const handleChange = (value: boolean) => {
        props.onChange(value)
    }

    if(props.isLoading) {
        return (
            <ReactLoading type='spin' color='#4C4DDC' width={18} height={18} className='ml-4' />
        )
    }

    return (
        <Switch checked={props.active} onChange={handleChange} as={Fragment}>
            {({ checked }) => (
                <button
                    className={`${checked ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                    <span className="sr-only">Enable notifications</span>
                    <span
                        className={`${checked ? 'translate-x-6' : 'translate-x-1'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                </button>
            )}
        </Switch>
    )
}
