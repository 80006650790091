import { useState } from 'react'
import MicrophoneIcon from '../../assets/images/microphone_black.svg'
import MicRecorder from 'mic-recorder-to-mp3'
import StopIcon from '../../assets/images/stop-circle-fill.svg'

export default function VoiceRecorrderMessage(props) {

    const [Mp3Recorder, setMp3Recorder] = useState(new MicRecorder({ bitRate: 128 }))
    const [isRecording, setIsRecording] = useState(false)
    const [endRecording, setEndRecording] = useState(false)

    const startRecording = () => {
        Mp3Recorder
            .start()
            .then(() => {
                setIsRecording(true)
            }).catch((e) => console.error(e));

    }

    const endRecord = () => {

        Mp3Recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                const file = new File(buffer, 'voice.mp3', {
                    type: blob.type,
                    lastModified: Date.now()
                })
                props.onChange(file)
                setEndRecording(true)
                setIsRecording(false)
            }).catch((e) => console.log(e));
    }

    return (
        <div>
            {
                !isRecording ?
                    <img onClick={startRecording} className='w-3' src={MicrophoneIcon} />
                    :
                    <img onClick={endRecord} className='w-5' src={StopIcon} />
            }
        </div>
    )
}
