import SearchIcon from '../../assets/images/search.svg'
import CalenderIcon from '../../assets/images/calender.svg'
import CameraIcon from '../../assets/images/camera.svg'
import SearchFolderIcon from '../../assets/images/search_folder.svg'
import Input from '../../components/Input'
import Pagination from '../../components/Pagination'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetParentsList } from '../../hook/patients'
import PageLoading from '../../components/PageLoading'
import moment from 'moment'
import ModalParentInformation from '../../components/patients/ModalParentInformation'

export default function List() {

    const navigate = useNavigate()
    const [offset, setOffset] = useState<number>(0)
    const [search, setSearch] = useState('')
    const [search2, setSearch2] = useState<string>('')
    const [page, setPage] = useState(1)
    const [parentId, setParentId] = useState('')
    const [showModalInformation, setShowModalInformation] = useState(false)
    const getParents = useGetParentsList(offset, search)

    const handleShowInformation = (slug: string) => {
        setParentId(slug)
        setShowModalInformation(true)
    }

    const handleChangePage = (pageNumber: number) => {
        var p = pageNumber - 1
        setPage(pageNumber)
        setOffset(p * 20)
    }

    useEffect(() => {
        const timeout = setTimeout(() => {

            setPage(1)
            setOffset(0)
            setSearch(search2)

        }, 1500)

        return () => clearTimeout(timeout)


    }, [search2])


    return (
        <div className='py-4'>
            {
                getParents.isLoading ?
                    <PageLoading />
                    :
                    <div className='px-6'>
                        <div className='text-center md:text-left'>
                            <span className='font-medium text-sm'>
                                New Patients
                            </span>
                            <span className='text-xs ml-2 text-black_opacity_40'>
                                {getParents.data?.count} Patients
                            </span>
                        </div>
                        <div className='mt-3 md:flex-row flex-col items-center flex gap-2 w-full'>
                            <div className='relative mt-2 md:mt-0 w-52'>
                                <Input value={search2} onChange={(e) => setSearch2(e.target.value)} classNameInput='text-xs px-3' placeholder='Search Names...' />
                                <img src={SearchIcon} className='absolute right-3 top-0 bottom-0 my-auto' />
                            </div>
                            {/* <div>
                                <Select
                                    items={[
                                        {
                                            id: 1,
                                            text: 'Sort by less Week Sessions'
                                        },
                                    ]}
                                    onChange={() => null}
                                    placeholder='Sort By'
                                />
                            </div> */}
                        </div>

                        <div className="relative overflow-x-auto mt-2">
                            <table className="w-full text-xs ">
                                <thead className='thead'>
                                    <tr>
                                        <td scope="col" className='td'>
                                            Caretaker
                                        </td>
                                        <td scope="col" className='td'>
                                            Patient
                                        </td>
                                        <td scope="col" className='td'>
                                            Age
                                        </td>
                                        <td scope="col" className='td'>
                                            Phone Number
                                        </td>
                                        <td scope="col" className='td'>
                                            Reservation Date
                                        </td>
                                        <td scope="col" className='td'>
                                            Session Type
                                        </td>
                                        <td scope="col" className='td'>
                                            Status
                                        </td>
                                        <td scope="col" className='td'>

                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        getParents.data?.results?.map((item: any) => {
                                            return (
                                                <tr key={item.id} className="border-b ">
                                                    <td scope="row" className="td">
                                                        <div className='flex items-center'>
                                                            <img src={item.parent?.avatar}
                                                                className='w-6 h-6 object-cover rounded-full' />
                                                            <div className='ml-2'>
                                                                {item.parent?.__str__}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td scope="row" className="td">
                                                        <div className='flex items-center'>
                                                            <img src={item.learner?.avatar}
                                                                className='w-6 h-6 object-cover rounded-full' />
                                                            <div className='ml-2'>
                                                                {item.learner?.__str__}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="td">
                                                        {item.learner?.age} Years Old
                                                    </td>

                                                    <td className="td">
                                                        {item.parent?.phone_number}
                                                    </td>
                                                    <td className="td">
                                                        {
                                                            item.next_meeting ?
                                                                <div className='flex items-center'>
                                                                    <img src={CalenderIcon} />
                                                                    <div className='ml-2'>
                                                                        {moment(item.next_meeting?.start_time).format('lll')}
                                                                    </div>
                                                                </div> : null
                                                        }

                                                    </td>
                                                    <td className="td capitalize">
                                                        {item.next_meeting?.meeting_type}
                                                    </td>
                                                    <td className='td'>
                                                        <div style={{
                                                            background: item.parent?.parent_status?.background_color,
                                                            color: item.parent?.parent_status?.font_color
                                                        }} className={`ml-2 font-medium p-2 py-1 w-fit rounded-1`}>
                                                            {item.parent?.parent_status?.text}
                                                        </div>
                                                    </td>
                                                    <td className='td text-primary'>
                                                        <div className='flex gap-6'>
                                                            <img
                                                                onClick={() => navigate(`/upcoming-sessions/session?hideborder=1&parent-id=${item.parent?.id}`)}
                                                                src={CameraIcon} />
                                                            <img onClick={() => handleShowInformation(item.parent?.id)}
                                                                src={SearchFolderIcon} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                        {
                            getParents.data?.count ?
                                <Pagination
                                    currentPage={page}
                                    onChange={(pageNumber) => handleChangePage(pageNumber)}
                                    pageSize={20}
                                    total={getParents.data?.count}
                                />
                                : null
                        }

                    </div>
            }
            <ModalParentInformation
                open={showModalInformation}
                close={() => setShowModalInformation(false)}
                parentId={parentId}
            />
        </div>
    )
}
