import ReactAudioPlayer from 'react-audio-player';
interface Props {
    source?: string,
}

const AudioPlayer = (props: Props) => {

    return (
        <ReactAudioPlayer
            className='w-full'
                src={props.source}
                controls
            />
    )
}

export default AudioPlayer