import { useMutation, useQuery } from '@tanstack/react-query'
import * as api from '../../api/messages'
import { STALETIME } from '../../config/const'
import { CreateConversationInterface } from '../../interface/Messages'

export const useGetConversations = (offset?:number, search?:string, queryText?:string) => {
    return useQuery(['conversations', offset ? offset : 0, search, queryText], () => api.GetConversations(offset, search, queryText), {
        retry: false
    })
}

export const useGetFollowers = (search:string) => {
    return useQuery(['followers', search], () => api.GetFollowers(search), {
        staleTime: STALETIME,
        retry: false
    })
}

export const useCreateConversation = () => {
    return useMutation((variables: CreateConversationInterface) => api.CreateConversation(variables))
}

export const useGetMessagesConversation = (conversation_id:any) => {
    return useQuery(['conversation', conversation_id], () => api.GetMessagesConversation(conversation_id), {
        retry: false,
        enabled: !!conversation_id,
        refetchInterval: 8000,
    })
}

export const useGetInfoConversation = (conversation_id:any) => {
    return useQuery(['conversation_info', conversation_id], () => api.GetInfoConversation(conversation_id), {
        retry: false,
        enabled: !!conversation_id
    })
}

export const useSendMessage = (conversation_id:any) => {
    return useMutation((variables: any) => api.SendMessage(conversation_id, variables))
}