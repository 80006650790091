import { useEffect, useState } from "react"
import Button from "../Button"
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useCancelMeetingList } from "../../hook/timing"
import moment from "moment"
import Input from "../Input"
import { CancelMetingListInterface } from "../../interface/Meeting"
import { toast } from "react-toastify"

interface Props {
    open: boolean,
    close: () => void,
    data: any,
    date: any,
    refetch: () => void,
}

export default function ModalConfrimCancelRequests(props: Props) {

    const cancelMeeting = useCancelMeetingList()
    const [reason, setReason] = useState<string>('')

    const handleCancelMeeting = () => {
        console.log(props.data);
        
        const array: string[] = []
        props.data?.map((item: any) => {
            array.push(item.user_meeting?.id)
        })

        const params:CancelMetingListInterface = {
            user_meeting_cancel_reason: reason,
            user_meeting_list: array,
        }

        cancelMeeting.mutateAsync(params).then((_) => {
            props.refetch()
            toast.success('Your request has been successfully registered')
            setReason('')
            setTimeout(() => {
                props.close()
            }, 1000)
        }).catch((err) => {
            toast.error(err.response?.data?.message)
        })
    }


    return (
        <>
            {
                props.open ? (

                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none max-w-3xl mx-auto">
                            <div className="relative  sm:h-auto w-full my-6 p-2">
                                <div className="border-0 h-full p-5 lg:min-w-full overflow-y-auto rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">


                                    <div className="w-full flex justify-between font-medium pb-4 border-b border-border">
                                        <div className="text-sm sm:text-base">
                                            Cancel all meetings - {moment(props.date).format('lll')}
                                        </div>
                                        <XMarkIcon onClick={props.close} className="w-5 sm:hidden" />
                                    </div>

                                    <div className="mt-5 text-xs">
                                        Are you sure you want to cancel all your meetings at {moment(props.date).format('lll')}?
                                    </div>

                                    <Input
                                        type="textarea"
                                        label="Reason"
                                        onChange={(e) => setReason(e.target.value)}
                                        placeholder="Please write your reason for canceling the meetings"
                                    />

                                    <div className="flex mt-2 flex-col-reverse sm:flex-row sm:gap-5 gap-2 pt-4 border-t border-border">
                                        <Button
                                            title="Cancel My Meetings"
                                            className="bg-red-100 font-medium"
                                            textColor="#C61A1A"
                                            height={43}
                                            onClick={handleCancelMeeting}
                                            isLoading={cancelMeeting.isLoading}
                                        />
                                        <Button
                                            title="Dimiss"
                                            height={43}
                                            className="font-medium"
                                            onClick={props.close}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div onClick={props.close} className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null

            }
        </>
    )
}
