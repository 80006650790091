import { useFormik } from 'formik'
import Input from '../Input'
import Button from '../Button'
import { useChangePassword, useGetProfileInfo } from '../../hook/user'
import PageLoading from '../PageLoading'
import * as Yup from 'yup'
import { toast } from 'react-toastify'

export default function AccountSetting() {

  const getProfile = useGetProfileInfo()
  const changePassword = useChangePassword()

  const formik = useFormik({
    initialValues: {
      current_password: '',
      new_password: '',
      repeat_new_password: '',
    },
    validationSchema: Yup.object({
      current_password: Yup.string()
        .required(),
      new_password: Yup.string()
        .required(),
      repeat_new_password: Yup.string()
        .required(),
    }),
    onSubmit: (values) => {
      if (values.new_password === values.repeat_new_password) {
        const params = {
          password: values.current_password,
          new_password: values.new_password
        }

        changePassword.mutateAsync(params).then((_) => {
          toast.success('The operation done successfully')
          window.location.href = '/auth/login'
        }).catch((err) => {
          toast.error(err.response?.data?.message)
        })

      } else {
        toast.error('The new password is not the same as repeating it')
      }
    }
  })

  return (
    <div className='p-5 pt-2'>
      {
        getProfile.isLoading ?
          <PageLoading />
          :
          <div>
            <Input
              label='Username'
              value={getProfile.data?.email}
              name='username'
              onChange={formik.handleChange}
              readonly
            />

            <div className='mt-4 text-sm font-medium'>
              Change Password
            </div>

            <Input
              label='Current Password'
              value={formik.values.current_password}
              name='current_password'
              onChange={formik.handleChange}
              type='password'
              error_text={formik.touched.current_password && formik.errors.current_password ? formik.errors.current_password : ''}
            />

            <Input
              label='New Password'
              value={formik.values.new_password}
              name='new_password'
              onChange={formik.handleChange}
              type='password'
              error_text={formik.touched.new_password && formik.errors.new_password ? formik.errors.new_password : ''}
            />

            <Input
              label='Repeat the new password'
              value={formik.values.repeat_new_password}
              name='repeat_new_password'
              onChange={formik.handleChange}
              type='password'
              error_text={formik.touched.repeat_new_password && formik.errors.repeat_new_password ? formik.errors.repeat_new_password : ''}
            />

            <div className='mt-8'>
              <Button
                title='Save Changes'
                onClick={formik.handleSubmit}
                isLoading={changePassword.isLoading}
              />
            </div>

          </div>
      }
    </div>
  )
}
