interface ObjectItems {
    id: number,
    text: string,
}

interface Props {
    toggle: number,
    onChange: (value: number) => void,
    items: ObjectItems[]
}

export default function Toggle(props: Props) {
    return (
        <div className='w-full bg-secondary h-10 rounded-2 p-[5px] flex font-normal'>
            {
                props.items.map((item: ObjectItems) => {
                    return (
                        <div onClick={() => props.onChange(item.id)} className={`flex-1 ${props.toggle === item.id ? 'bg-primary text-white' : 'text-primary'} cursor-pointer transition-all flex justify-center items-center rounded-[4px] text-sm`}>
                            {item.text}
                        </div>
                    )
                })
            }
        </div>
    )
}
