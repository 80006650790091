import { useMutation, useQuery } from '@tanstack/react-query'
import * as api from '../../api/patients'
import { MeetingSubmitInterface } from '../../interface/Patients'


export const useGetForms = () => {
    return useQuery(["forms"], () => api.GetForms(), {
        retry: false,
    })
}

// Parent

export const useGetParentsList = (offset: number = 0, search?: string, queryText?:string) => {
    return useQuery(["parents", offset, search, queryText], () => api.GetParentsList(offset, search, queryText), {
        retry: false,
    })
}

export const useGetParentDetail = (parentId: any) => {
    return useQuery(["parent_detail", parentId],
        () => api.GetParentDetail(parentId), {
        retry: false,
        enabled: !!parentId,
    })
}

export const useGetEvaluators = () => {
    return useQuery(["evaluators"], () => api.GetEvaluators(), {
        retry: false,
    })
}
export const useGetReceptionists = () => {
    return useQuery(["receptionists"], () => api.GetReceptionists(), {
        retry: false,
    })
}


export const useParentSubmit = (parentId: string) => {
    return useMutation((variables: MeetingSubmitInterface) => api.ParentSubmit(parentId, variables))
}

export const useParentDeactivate = () => {
    return useMutation((parentId: string) => api.ParentDeactivate(parentId))
}


// Meetings

export const useGetMeetingsList = (offset: number = 0) => {
    return useQuery(["meetings"], () => api.GetMeetingsList(offset), {
        retry: false,
    })
}

export const useGetMeetingDetail = (meetingId: any) => {
    return useQuery(["meeting_detail", meetingId],
        () => api.GetMeetingDetail(meetingId), {
        retry: false,
        enabled: !!meetingId,
    })
}

export const useCancelMeeting = () => {
    return useMutation((meetingId: string) => api.CancelMeeting(meetingId))
}

export const useStartMeeting = () => {
    return useMutation((meetingId: string) => api.StartMeeting(meetingId))
}

export const useSubmitMeeting = (meetingId: string) => {
    return useMutation((variables: any) => api.SubmitMeeting(meetingId, variables))
}