import axios from 'axios'
import { BASE_URL } from './const'
import { Auth } from '../enums/Enums'

const axiosInstance = axios.create({
    baseURL: BASE_URL
});

axiosInstance.interceptors.request.use(async function (config) {
    const token = localStorage.getItem(Auth.token_name) ? "token " + localStorage.getItem(Auth.token_name) : null
    
    config.headers.Authorization = token
    // config.headers.responseType = "application/json"
    return config
});

export default axiosInstance