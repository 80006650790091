interface Props {
    type?: string,
    placeholder?: string,
    className?: string,
    classNameInput?: string,
    icon?: any,
    name?: string,
    value?: string,
    error_text?: string,
    label?: string,
    readonly?: boolean,
    iconRight?: any,
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
}

export default function Input(props: Props) {
    return (
        <div className={`w-full  ${props.className}`}>
            {
                props.label &&
                <div className="mt-4 text-xs">
                    {props.label}
                </div>
            }
            {
                props.type === 'textarea' ?
                    <textarea onChange={props.onChange} className="border p-3 border-border w-full text-sm mt-2 rounded-1 h-20" />
                    :
                    <div className={`h-10 relative ${props.label && 'mt-2'}`}>
                        {
                            props.icon &&
                            <img alt="" src={props.icon} className='absolute left-3 top-0 bottom-0 my-auto' />
                        }
                        <input
                            readOnly={props.readonly}
                            name={props.name}
                            value={props.value}
                            type={props.type}
                            placeholder={props.placeholder}
                            className={`border ${props.readonly ? 'bg-[#EEEEEE] text-black' : 'text-primary'} text-sm border-border w-full h-full rounded-[4px] ${props.icon ? 'pl-10' : ' px-2'} ${props.classNameInput}`}
                            onChange={props.onChange}
                        />
                        {
                            props.iconRight &&
                            <img alt="" src={props.iconRight} className='absolute right-3 top-0 bottom-0 my-auto' />
                        }
                    </div>

            }

            {/* {
                props.type === 'password' &&
                <img src={EyeIcon} className='absolute right-3 top-0 bottom-0 my-auto' />
            } */}

            {
                props.error_text && props.error_text != '' ?
                    <div className='text-xs text-red-600 mt-2 mr-2'>
                        {props.error_text}
                    </div>
                    : null
            }
        </div>
    )
}
