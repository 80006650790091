
interface Props {
    title: string,
    description: string,
    date: string,
    time: string,
}

const NotificationItem = (props: Props) => {
    return (
        <div className='mt-2 bg-primary-opacity-back py-3 rounded-md2'>
            <div className='pb-3 border-b border-primary-opacity-back'>
                <div className='font-semibold text-primary'>
                    {props.title}
                </div>
                {
                    props.description &&
                    <div className='mt-3 text-xs'>
                        {props.description}
                    </div>
                }
            </div>

            <div className='mt-3 flex justify-between text-primary text-xs'>
                <div>
                    {props.date}
                </div>
                <div>
                    {props.time}
                </div>
            </div>
        </div>
    )
}

export default NotificationItem