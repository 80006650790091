import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useGetInfoConversation, useGetMessagesConversation, useSendMessage } from '../../hook/messages'
import { toast } from 'react-toastify'
import moment from 'moment'
import VoiceRecorrderMessage from './VoiceRecorrderMessage'
import Button from '../Button'
import AttachIcon from '../../assets/images/attach.svg'
import AudioPlayer from '../AudioPlayer'

interface Props {
    conversation_id: string
}

export default function ConversationComponent(props: Props) {

    const getMessages = useGetMessagesConversation(props.conversation_id)
    const getInfoConversation = useGetInfoConversation(props.conversation_id)
    const sendMessage = useSendMessage(props.conversation_id)
    const [message, setMessage] = useState<string>('')
    const [file, setFile] = useState<File>()

    const onDrop = useCallback((acceptedFiles: File[]) => {

        setFile(acceptedFiles[0])

    }, [])


    useEffect(() => {

        if (file) {
            const formData = new FormData()
            formData.append('message', '')
            formData.append('attachment_file', file)

            sendMessage.mutateAsync(formData).then((_) => {
                getMessages.refetch()
                setFile(undefined)
            }).catch((err) => {
                toast.error(err.response?.data?.message)
            })
        }

    }, [file])
    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const handleSendMessage = () => {
        const formData = new FormData()
        formData.append('message', message)

        sendMessage.mutateAsync(formData).then((_) => {
            getMessages.refetch()
            setMessage('')
        }).catch((err) => {
            toast.error(err.response?.data?.message)
        })
    }

    return (
        <Fragment>
            <div className="border-b border-border pb-4">
                <div className="font-medium">
                    Conversation with {getInfoConversation.data?.contact?.first_name}
                </div>
                <div className="mt-4 w-full items-center px-4 flex h-[46px] border border-border rounded-2">
                    <img src={getInfoConversation.data?.contact?.avatar} className="w-6 h-6 rounded-full object-cover" />
                    <div className="ml-2 text-sm">
                        {getInfoConversation.data?.contact?.first_name + ' ' + getInfoConversation.data?.contact?.last_name}
                    </div>
                </div>

            </div>

            <div className="flex-1 flex flex-col">
                <div className="flex-1 flex flex-col justify-end overflow-y-auto">

                    {
                        getMessages.data?.results?.map((item: any) => {
                            if (getInfoConversation.data?.contact?.id == item.user.id) {
                                return (
                                    <div key={item.id} className="flex justify-end mt-4">
                                        <div className={`${item.content_type !== 'audio/mpeg' ? 'bg-yellow px-4 max-w-[70%]' : 'w-full'} min-w-[178px] py-2 rounded-2`}>
                                            <div className="text-sm">
                                                {
                                                    item.attachment_file ?
                                                        item.content_type === 'audio/mpeg' ?
                                                            <AudioPlayer
                                                                source={item.attachment_file}
                                                            />
                                                            :
                                                            <a target="_blank" href={item.attachment_file} className="text-primary font-medium cursor-pointer text-sm">
                                                                Download File
                                                            </a>
                                                        :
                                                        item.message
                                                }
                                            </div>
                                            <div className="mt-[10px] text-xs">
                                                {moment(item.insert_dt).format('HH:mm')}
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={item.id} className="flex mt-4">
                                        <div className={`${item.content_type !== 'audio/mpeg' ? 'bg-[#E9EAEC] px-4 max-w-[70%]' : 'w-full'} min-w-[178px]   py-2  rounded-2`}>
                                            <div className="text-sm">
                                                {
                                                    item.attachment_file ?
                                                        item.content_type === 'audio/mpeg' ?
                                                            <AudioPlayer
                                                                source={item.attachment_file}
                                                            />
                                                            :
                                                            <a target="_blank" href={item.attachment_file} className="text-primary font-medium cursor-pointer text-sm">
                                                                Download File
                                                            </a>
                                                        :
                                                        item.message
                                                }
                                            </div>
                                            <div className="mt-[10px] text-xs text-right">
                                                {moment(item.insert_dt).format('HH:mm')}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }

                </div>
                <div className="min-h-[51px] items-center mt-4 flex justify-between border border-border px-1 rounded-1">
                    <input
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Write your Message"
                        className="ml-2 text-sm"
                        readOnly={sendMessage.isLoading} />

                    <div className="flex gap-5 items-center">
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <img className="w-4" src={AttachIcon} />
                        </div>
                        <VoiceRecorrderMessage
                            onChange={(f: any) => setFile(f)}
                        />
                        <div className="w-[93px]">
                            <Button
                                title='Send'
                                onClick={handleSendMessage}
                                isLoading={sendMessage.isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
